import { createObjectCsvStringifier } from 'csv-writer';
import { saveAs } from 'file-saver';
import { ObjectStringifierHeader } from 'csv-writer/src/lib/record';
import moment from 'moment';
import JSZip from 'jszip';

export interface Header {
	id: string;
	title: string;
}

export type Row = Record<string, string | number>;

export type CSVDataMapper = {
	id: string,
	generate: () => {
		blob: Blob;
		path: string;
	}[],
};

export const formatExportTimestamp = (d: Date | string): string => moment(d).format('YYYY-MM-DD hh:mm:ss');
export const formatExportDate = (d: Date | string): string => moment(d).format('YYYY-MM-DD');

export const getCSVBlob = (header: ObjectStringifierHeader, rows: Row[]) => {
	const createCsvStringifier = createObjectCsvStringifier;
	const csvStringifier = createCsvStringifier({
		header,
	});

	const csvString = csvStringifier.getHeaderString() + csvStringifier.stringifyRecords(rows);

	return new Blob(
		[new Uint8Array([0xEF, 0xBB, 0xBF]), csvString],
		{ type: 'data:text/csv' },
	);
};

export const sanitizeFileName = (unsafeString: string): string => {
	return unsafeString
		.toLowerCase()
		.replace(/[^a-z0-9\s]/gi, '') // replace all sensitive characters with space
		.trim() // trim off any whitespace from start/end
		.replace(/[ ]/gi, '-'); // convert white space to hyphens
};

const zipBlobs = (blobs: { path: string, blob: Blob }[]): Promise<Blob> => {
	const zip = new JSZip();

	blobs.forEach(b => {
		zip.file(b.path, b.blob);
	});

	return zip.generateAsync({ type: 'blob' });
};

export const generateMultiChartBlobs = (
	csvConfig: [ObjectStringifierHeader, Row[]][],
	safeFileName: string,
	folder?: boolean,
) => {
	const blobs = csvConfig.map((b, i) => ({
		path: `${safeFileName}/${safeFileName}-${i + 1}.csv`,
		blob: getCSVBlob(b[0], b[1]),
	}));
	return blobs;
};

export const downloadChartData = (header: ObjectStringifierHeader, rows: Row[], unsafeFileName: string) => {
	const safeFileName = sanitizeFileName(unsafeFileName);

	const blob = getCSVBlob(header, rows);
	saveAs(blob, `${safeFileName}.csv`);
};

export const downloadMultiChartData = async (csvConfig: [ObjectStringifierHeader, Row[]][], unsafeFileName: string) => {
	const safeFileName = sanitizeFileName(unsafeFileName);
	const blobs = generateMultiChartBlobs(csvConfig, safeFileName);

	const zippedBlob = await zipBlobs(blobs);
	saveAs(zippedBlob, `${safeFileName}.zip`);
};

export const downloadZip = async (blobs: { path: string, blob: Blob }[], unsafeFileName: string) => {
	const safeFileName = sanitizeFileName(unsafeFileName);
	const zippedBlob = await zipBlobs(blobs.map(b => ({
		...b,
		// nest path inside final filename to ensure it's always nested in an approriate folder when unzipped
		path: `${safeFileName}/${b.path}`,
	})));

	saveAs(zippedBlob, `${safeFileName}.zip`);
};
