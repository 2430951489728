/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsSiteUserEntity from 'Models/Security/Acl/VisitorsSiteUserEntity';
import AdministratorSiteUserEntity from 'Models/Security/Acl/AdministratorSiteUserEntity';
import OrganisationAdminSiteUserEntity from 'Models/Security/Acl/OrganisationAdminSiteUserEntity';
import SiteUserSiteUserEntity from 'Models/Security/Acl/SiteUserSiteUserEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import axios from 'axios';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any further imports here] end

export interface ISiteUserEntityAttributes extends IModelAttributes {
	email: string;
	userName: string;
	twoFactorEnabled: boolean;
	firstName: string;
	lastName: string;
	contactNumber: string;

	sites: Array<
		| Models.SiteUserSite
		| Models.ISiteUserSiteAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SiteUserEntity', 'Site User')
// % protected region % [Customise your entity metadata here] end
export default class SiteUserEntity extends Model
	implements ISiteUserEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSiteUserEntity(),
		new AdministratorSiteUserEntity(),
		new OrganisationAdminSiteUserEntity(),
		new SiteUserSiteUserEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		'userName',
		'twoFactorEnabled',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		order: 10,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	@observable
	@attribute()
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] on begin
	@CRUD({
		name: 'TwoFactorEnabled',
		displayType: 'hidden',
		order: 20,
		createFieldType: 'hidden',
		headerColumn: false,
		searchable: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public twoFactorEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'UserName'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'UserName',
		displayType: 'hidden',
	})
	public userName: string;
	// % protected region % [Modify props to the crud options here for attribute 'UserName'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] off begin
	@Validators.Length(12, 128)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		order: 30,
		createFieldType: 'password',
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] off begin
	@Validators.Custom('Password Match', (e: string, target: SiteUserEntity) => {
		return new Promise(resolve => resolve(target.password !== e ? 'Password fields do not match' : null));
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		order: 40,
		createFieldType: 'password',
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] off begin
	@Validators.Required()
	@observable
	@attribute<SiteUserEntity, string>()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] off begin
	@Validators.Required()
	@observable
	@attribute<SiteUserEntity, string>()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Contact Number'] off begin
	@observable
	@attribute<SiteUserEntity, string>()
	@CRUD({
		name: 'Contact Number',
		displayType: 'textfield',
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public contactNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Contact Number'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Site'] off begin
		name: 'Site',
		displayType: 'reference-multicombobox',
		order: 80,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.SiteUserSite,
		optionEqualFunc: makeJoinEqualsFunc('siteId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'siteUserEntity',
			oppositeEntityName: 'siteEntity',
			relationName: 'siteUser',
			relationOppositeName: 'site',
			entity: () => Models.SiteUserEntity,
			joinEntity: () => Models.SiteUserSite,
			oppositeEntity: () => Models.SiteEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Site'] end
	})
	public sites: Models.SiteUserSite[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISiteUserEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISiteUserEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.userName !== undefined) {
				this.userName = attributes.userName;
			}
			if (attributes.twoFactorEnabled !== undefined) {
				this.twoFactorEnabled = attributes.twoFactorEnabled;
			}
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.contactNumber !== undefined) {
				this.contactNumber = attributes.contactNumber;
			}
			if (attributes.sites !== undefined && Array.isArray(attributes.sites)) {
				for (const model of attributes.sites) {
					if (model instanceof Models.SiteUserSite) {
						this.sites.push(model);
					} else {
						this.sites.push(new Models.SiteUserSite(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		sites {
			${Models.SiteUserSite.getAttributes().join('\n')}
			site {
				${Models.SiteEntity.getAttributes().join('\n')}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			sites: {},
		};

		if (formMode === 'create') {
			relationPath.password = {};

			if (this.password !== this._confirmPassword) {
				throw Error('Password fields do not match');
			}
		}
		/* Gets the names of the Sites selected by the user eg: Ward 1, Ward 2 etc */
		const siteNames: string[] = [];
		this.sites.map(site => siteNames.push(site.site.name));

		await this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'sites',
						],
					},
				],
			},
		);
		return axios.post(`${SERVER_URL}/api/register/send-welcome-email`, {
			FirstName: this.firstName,
			UserName: this.email,
			Sites: siteNames,
		})
			.then(response => {
				console.log(response);
			})
			.catch(error => {
				console.log(error);
			});
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return (this.firstName && this.lastName)
			? `${this.firstName} ${this.lastName}`
			: this.email;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SiteUserEntity.prototype, 'created');
CRUD(modifiedAttr)(SiteUserEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
