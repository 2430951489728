import React, { useState } from 'react';
import { createContext } from 'react';
import { observer } from 'mobx-react';
import { action, IObservableObject, observable } from 'mobx';

interface ISubmitFormProgressBarContext {
	progressState: {progress: number} & IObservableObject;
	setProgress: (p: number) => void;
}

const defaultState: ISubmitFormProgressBarContext = {
	progressState: observable({
		progress: 0,
	}),
	setProgress: () => {},
};

export const SubmitFormProgressBarContext = createContext(defaultState);

export const SubmitFormProgressBarContextProvider: React.FC = observer(({ children }) => {
	const progressState = observable({
		progress: 0,
	});

	const setProgress = action((p: number) => {
		progressState.progress = p * 100;
	});

	return (
		<SubmitFormProgressBarContext.Provider value={{
			progressState,
			setProgress,
		}}
		>
			{children}
		</SubmitFormProgressBarContext.Provider>
	);
});
