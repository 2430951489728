/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React from 'react';
import { Form } from 'Forms/Schema/Question';
import { Combobox } from 'Views/Components/Combobox/Combobox';
// % protected region % [Add extra imports here] off begin
// % protected region % [Add extra imports here] end

type formOptionProps = {
	schema: Form;
}

interface ComboBoxOptions {
	display: string;
	value: string;
}

export default function FormOptions(props: formOptionProps) {
	const { schema } = props;
	return (
		<div className="form-options">
			<Combobox
				label="Pagination Type"
				model={schema.pagination}
				modelProperty="type"
				options={getPaginationOptions()}
			/>
		</div>
	);
}

function getPaginationOptions(): Array<ComboBoxOptions> {
	// % protected region % [Override getStylingOptions here] off begin
	return paginationTypeOptions.map(option => ({ display: option, value: option }));
	// % protected region % [Override getStylingOptions here] end
}

const paginationTypeOptions = ['scroll', 'page', 'url'];
