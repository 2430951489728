import React, { useState, useCallback, useEffect } from 'react';
import {
	Button,
	Header,
	Modal,
} from 'semantic-ui-react';

interface IPublicSubmissionFormTimer {
	open: boolean;
	openModal: () => void;
	closeModal: () => void;
	backToStart: () => void;
	currentStep: any;
}

let timer: NodeJS.Timeout | undefined;
let modalTimer: NodeJS.Timeout | undefined;

function FormTimerModal(props: IPublicSubmissionFormTimer) {
	const {
		open,
		openModal,
		closeModal,
		backToStart,
		currentStep,
	} = props;

	const setModalTimer = useCallback(() => {
		if (!modalTimer) {
			modalTimer = setTimeout(() => {
				closeModal();
				backToStart();
			}, 20000);
		}
	}, [backToStart, closeModal]);

	const setTimer = useCallback(() => {
		if (currentStep === 'form') {
			if (!timer) {
				timer = setTimeout(() => {
					openModal();
					setModalTimer();
				}, 30000);
			}
		}
	}, [currentStep, openModal, setModalTimer]);

	const clearTimer = useCallback(() => {
		if (timer) {
			clearTimeout(timer);
			timer = undefined;
		}
	}, []);

	const clearModalTimer = useCallback(() => {
		if (modalTimer) {
			clearTimeout(modalTimer);
			modalTimer = undefined;
		}
	}, []);

	const handleClickDown = useCallback(() => {
		clearTimer();
		setTimer();
		clearModalTimer();
	}, [clearTimer, setTimer, clearModalTimer]);

	useEffect(() => {
		setTimer();
		window.addEventListener('keydown', handleClickDown);
		window.addEventListener('wheel', handleClickDown);
		window.addEventListener('pointermove', handleClickDown);
		window.addEventListener('pointerdown', handleClickDown);

		return () => {
			window.removeEventListener('keydown', handleClickDown);
			window.removeEventListener('wheel', handleClickDown);
			window.removeEventListener('pointermove', handleClickDown);
			window.removeEventListener('pointerdown', handleClickDown);
			clearTimer();
			clearModalTimer();
		};
	}, [setTimer, clearModalTimer, clearTimer, handleClickDown]);

	return (
		<Modal
			onClose={() => closeModal()}
			onOpen={() => openModal()}
			open={open}
		>
			<Modal.Header>Survey Timeout</Modal.Header>
			<Modal.Content image>
				<Modal.Description>
					<Header>Do you wish to continue?</Header>
					<p>If you wish to finish this survey select continue survey, otherwise select cancel. </p>
					<p>Your feedback will not be saved if you cancel now.  </p>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button color="red" onClick={() => { closeModal(); backToStart(); }}>
					NO, CANCEL SURVEY
				</Button>
				<Button
					content="YES, CONTINUE SURVEY"
					labelPosition="right"
					icon="checkmark"
					onClick={() => closeModal()}
					positive
				/>
			</Modal.Actions>
		</Modal>
	);
}

export default FormTimerModal;
