import React, { Component, ReactNode } from 'react';
import {
	action,
	autorun,
	observable,
	runInAction,
} from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';
import classNames from 'classnames';
import { Button } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { Form, Question } from './Schema/Question';
import { SlideTile } from './SlideTile';
import { buildValidationErrorMessage } from './Validators/ValidationUtils';
import CheckDisplayConditions from './Conditions/ConditionUtils';
import { getNestedQuestions } from './Questions/QuestionUtils';
import { Switch, Route, Redirect } from 'react-router';
import { store } from 'Models/Store';
import { SubmitFormProgressBarContext } from '../Views/Components/ProgressBar/SubmitFormProgressBarContext';

export interface IFormCustomProps {
	isReadOnly?: boolean;
	className?: string;
	submitText?: string;
	disableShowConditions? : boolean;
}

export interface IFormTileCustomProps<T> extends IFormCustomProps {
	schema: Form;
	model: T;
	onSubmit?: (model: T) => void;
	preview?: boolean;
	routePath?: string;
}

@observer
export class FormTileCustom<T> extends Component<IFormTileCustomProps<T>> {
	@observable
	private reValidate: boolean = false;

	@observable
	private isMobile: boolean = false;

	static contextType = SubmitFormProgressBarContext;
	context: React.ContextType<typeof SubmitFormProgressBarContext> | undefined;

	static defaultProps: Partial<IFormCustomProps> = {
		submitText: 'Submit',
	};

	@action
	private handlePageResized = () => {
		this.isMobile = window.innerWidth < 576;
	}

	componentDidMount() {
		autorun(() => {
			this.calculateProgress();
		});

		this.handlePageResized();

		window.addEventListener('resize', this.handlePageResized);
		window.addEventListener('orientationchange', this.handlePageResized);
		window.addEventListener('load', this.handlePageResized);
		window.addEventListener('reload', this.handlePageResized);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handlePageResized);
		window.removeEventListener('orientationchange', this.handlePageResized);
		window.removeEventListener('load', this.handlePageResized);
		window.removeEventListener('reload', this.handlePageResized);
	}

	private calculateProgress = () => {
		const { schema } = this.props;

		const totalNumOfPages = schema.slides.filter(s => !s.disabled).length;

		this.context?.setProgress(this.currentSlideNumber / totalNumOfPages);
	}

	private onSubmit = (): void => {
		const { onSubmit, model } = this.props;

		if (onSubmit) {
			if (this.validateForm()) {
				onSubmit(model);
			} else {
				runInAction((): void => {
					this.reValidate = true;
				});
			}
		}
	};

	private isQuestionShown = (question: Question): boolean => {
		const { showConditions } = question;
		const { disableShowConditions, model, schema } = this.props;

		if (showConditions !== undefined && !disableShowConditions) {
			return showConditions.every(condition => { return CheckDisplayConditions(condition, model, schema); });
		}

		return true;
	};

	private validateQuestion = (question: Question): boolean => {
		const { model, schema } = this.props;
		const { validators } = question;
		let errorMessage: string[] = [];

		if (this.isQuestionShown(question)) {
			if (question.validators !== undefined) {
				errorMessage = buildValidationErrorMessage(validators, model, schema, false);
				return _.filter(errorMessage, e => e).length === 0;
			}
		}
		return true;
	};

	private validateQuestions = (questions: Question[]): boolean => {
		let valid: boolean = true;

		questions.forEach(q => {
			valid = valid && this.validateQuestion(q);
		});

		return valid;
	};

	private validateForm = (): boolean => {
		const { schema } = this.props;
		const questions = _.flatMap(schema.slides, o => getNestedQuestions(o.contents));
		return this.validateQuestions(questions);
	};

	private validateSlide = (): boolean => {
		const { schema } = this.props;
		const questions = _.flatMap(getNestedQuestions(schema.slides[this.currentSlideNumber - 1].contents));
		return this.validateQuestions(questions);
	};

	@observable
	private currentSlideNumber: number = 1;

	@action
	private nextSlide = () => {
		if (this.validateSlide()) {
			this.currentSlideNumber++;
		} else {
			this.reValidate = true;
		}
	}

	@action
	private nextSlideUrl = (slide: number) => {
		const questions = _.flatMap(getNestedQuestions(this.props.schema.slides[slide].contents));
		if (this.validateQuestions(questions)) {
			store.routerHistory?.push(`./${slide + 2}`);
		} else {
			this.reValidate = true;
		}
	}

	@action
	private previousSlide = () => {
		this.currentSlideNumber--;
	}

	private renderScrollForm(slideTiles: ReactNode[]): ReactNode {
		const {
			submitText, onSubmit,
		} = this.props;

		return (
			<>
				{slideTiles}
				<If condition={onSubmit !== undefined}>
					<Button onClick={this.onSubmit}>{submitText}</Button>
				</If>
			</>
		);
	}

	private renderPageForm(slideTiles: ReactNode[]): ReactNode {
		const {
			submitText, onSubmit, preview,
		} = this.props;
		const pages = slideTiles.length;
		return (
			<>
				<If condition={preview}>
					<h5>This is a preview using page based pagination.</h5>
				</If>
				{slideTiles[this.currentSlideNumber - 1]}
				<div className="slide-button__container">
					<Button
						className="icon-arrow-left icon-left btn--outline"
						onClick={this.previousSlide}
						disabled={this.currentSlideNumber === 1}
					>
						Previous {!this.isMobile ? 'Slide' : ''}
					</Button>
					{this.currentSlideNumber !== pages
						&& (
							<Button
								className="icon-arrow-right icon-right btn--solid"
								onClick={this.nextSlide}
							>
								Next {!this.isMobile ? 'Slide' : ''}
							</Button>
						)}
					<If condition={onSubmit !== undefined && this.currentSlideNumber === pages}>
						<Button
							className="icon-arrow-right icon-right btn--solid btn--secondary"
							onClick={this.onSubmit}
						>
							{submitText}
						</Button>
					</If>
				</div>
			</>
		);
	}

	private renderUrlForm(slideTiles: ReactNode[]): ReactNode {
		const {
			onSubmit, routePath,
		} = this.props;
		const pages = slideTiles.length;
		let pageNumber = 1;
		const history = store.routerHistory;
		return (
			<>
				<section className="content">
					<Switch>
						{slideTiles.map((slide, i) => {
							const hasPrevPage = i > 0;
							const hasNextPage = i < (pages - 1);
							return (
								<Route key={pageNumber++} path={`${routePath}/slide/${i + 1}/`}>
									{slide}

									<div className="slide-button__container">

										<If condition={hasPrevPage}>
											<Button
												disabled={!hasPrevPage}
												onClick={() => history?.push(`./${i}`)}
											>
												Previous Slide
											</Button>
										</If>
										<If condition={hasNextPage}>
											<Button
												disabled={!hasNextPage}
												onClick={() => {
													this.nextSlideUrl(i);
												}}
											>
												Next Slide
											</Button>
										</If>
										<If condition={!hasNextPage}>
											<If condition={onSubmit !== undefined}>
												<Button
													onClick={this.onSubmit}
												>
													Submit
												</Button>
											</If>
										</If>
									</div>
								</Route>
							);
						})}
						<Redirect to={`${routePath}/slide/1`} />
					</Switch>
				</section>
			</>
		);
	}

	private renderForm(formType: string, slideTiles: ReactNode[]): ReactNode {
		const { preview } = this.props;

		if (preview && formType === 'url') {
			return this.renderPageForm(slideTiles);
		}
		switch (formType) {
			case 'page':
				return this.renderPageForm(slideTiles);
			case 'url':
				return this.renderUrlForm(slideTiles);
			case 'scroll':
				return this.renderScrollForm(slideTiles);
			default:
				return this.renderPageForm(slideTiles);
		}
	}

	public render(): ReactNode {
		const {
			className, schema, isReadOnly, disableShowConditions, model,
		} = this.props;

		const slideTiles = schema.slides.filter(s => !s.disabled).map((slide, i): ReactNode => {
			const key = `${slide.name}-${i}`;
			return (
				<SlideTile
					key={key}
					model={model}
					schema={schema}
					isReadOnly={isReadOnly}
					disableShowConditions={disableShowConditions}
					contents={slide.contents}
					name={slide.name}
					reValidate={this.reValidate}
					isBaseFormSlide={slide.isBaseFormSlide}
				/>
			);
		});

		return (
			<div className={classNames('forms-tile', className)}>
				{this.renderForm(schema.pagination.type, slideTiles)}
			</div>
		);
	}
}
