import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { QuestionType, ValidatorType } from 'Forms/Schema/Question';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import TileOptions from '../Options/TileOptions';
import CompareText from 'Forms/Conditions/CompareText';
import ValidateRadio from 'Forms/Validators/ValidateRadio';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
import { TextField } from '../../../Views/Components/TextBox/TextBox';
import { Combobox, ComboboxOption } from '../../../Views/Components/Combobox/Combobox';
import { action } from 'mobx';
import classnames from 'classnames';
import { ScaleQuestion } from '../../../Views/Components/ScaleQuestion/ScaleQuestion';

interface ScaleQuestionOptions {
	minValueDescription: string;
	maxValueDescription: string;
	type: 'rating' | 'nps';
}

@observer
export class ScaleQuestionTileOptions extends Component<QuestionTileOptionsProps> {
	public render() {
		const { question, schema } = this.props;

		return (
			<TileOptions
				question={question}
				schema={schema}
				hasShowConditions={false}
				hasValidators
				hasTooltip
				tooltipInputLabel="Scale Question description"
				optionsChildren={(
					<>
						<TextField
							label="Min value description"
							model={question.options}
							modelProperty="minValueDescription"
						/>
						<TextField
							label="Max value description"
							model={question.options}
							modelProperty="maxValueDescription"
						/>
						<Combobox
							label="Type"
							model={question.options}
							modelProperty="type"
							searchable={false}
							options={[{ value: 'rating', display: 'Rating' }, { value: 'nps', display: 'NPS' }]}
						/>
					</>
				)}
			/>
		);
	}
}

export interface IScaleQuestionTileProps<T> extends QuestionComponentProps<T> {
	options: ScaleQuestionOptions;
}

@observer
export class ScaleQuestionTile<T> extends QuestionComponent<T, IScaleQuestionTileProps<T>> implements IQuestionTile {
	static displayName = 'Scale Question';

	static questionType: QuestionType = 'scale';

	static optionsMenu = ScaleQuestionTileOptions;

	static compareFunction = CompareText;

	static validateFunction = ValidateRadio;

	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
	];

	static validatorOptions: { display: string, value: ValidatorType }[] = [
		{ display: 'Required', value: 'required' },
	];

	private options: {
		values: ComboboxOption<string>[]
	} = {
		values: new Array(11).fill(0).map((x, i) => ({
			display: i.toString(),
			value: i.toString(),
		})),
	};

	@action
	componentDidMount() {
		const { options } = this.props;

		if (options && !options.type) {
			options.type = 'rating';
		}
	}

	public render() {
		const {
			title, id, model, isReadOnly, className, toolTip, options: questionOptions,
		} = this.props;

		return (
			<>
				<ScaleQuestion
					id={id}
					name={id}
					model={model}
					modelProperty={id}
					label={title}
					tooltip={toolTip}
					isReadOnly={isReadOnly}
					options={this.options && this.options.values
						? this.options.values.map(value => ({ display: value.value, value: value.value }))
						: []}
					className={classnames(
						className,
						{ nps: questionOptions.type === 'nps' },
						{ rating: questionOptions.type === 'rating' },
					)}
					minValueDescription={questionOptions.minValueDescription}
					maxValueDescription={questionOptions.maxValueDescription}
				/>
			</>
		);
	}
}
