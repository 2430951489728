import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { QuestionType, ValidatorType } from 'Forms/Schema/Question';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import TileOptions from '../Options/TileOptions';
import { hasRequiredValidator } from '../../Validators/ValidationUtils';
import CompareBoolean from 'Forms/Conditions/CompareBoolean';
import ValidateBoolean from 'Forms/Validators/ValidateBoolean';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
import classNames from 'classnames';
import { DisplayType } from '../../../Views/Components/Models/Enums';
import { action } from 'mobx';
import PillQuestionOptions from '../Options/PillQuestionOptions';
import {
	ThreeOptionsToggleableButton,
} from '../../../Views/Components/ThreeOptionsToggleable/ThreeOptionsToggleableButton';

@observer
export class ThreeOptionsQuestionTileOptions extends Component<QuestionTileOptionsProps> {
	public render() {
		const { question, schema } = this.props;

		return (
			<>
				<TileOptions
					question={question}
					schema={schema}
					hasShowConditions={false}
					hasValidators={false}
					hasTooltip
					tooltipInputLabel="3 Options Toggleable Question description"
				/>
				<PillQuestionOptions
					schema={schema}
					question={question}
				/>
			</>
		);
	}
}

interface ThreeOptionsQuestionTileQuestionOptions {
	values: {
		id: string;
		value: string;
	}[]
}

export interface IThreeOptionsQuestionTileProps<T> extends QuestionComponentProps<T> {
	options: ThreeOptionsQuestionTileQuestionOptions;
}

@observer
// eslint-disable-next-line max-len
export class ThreeOptionsQuestionTile<T> extends QuestionComponent<T, IThreeOptionsQuestionTileProps<T>> implements IQuestionTile {
	static displayName = '3 Options Toggleable Question';

	static questionType: QuestionType = '3-options';

	static optionsMenu = ThreeOptionsQuestionTileOptions;

	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
		{ display: 'Not equal', value: 'notEqual' },
	];

	static validatorOptions: { display: string, value: ValidatorType }[] = [
		{ display: 'Required', value: 'required' },
	];

	static stylingOptions = undefined;

	static compareFunction = CompareBoolean;

	static validateFunction = ValidateBoolean;

	@action
	componentDidMount() {
		const { model, options, id } = this.props;

		if (options.values) {
			if (options.values.length <= 0) {
				options.values.push({
					value: 'Option 1',
					id: `${id}-1`,
				});
			}

			for (const option of options.values) {
				const value = model[option.id];

				if (!value) {
					model[option.id] = false;
				}
			}
		}
	}

	public render() {
		const {
			title, model, id, isReadOnly, validators, toolTip, className, checkValidation, options,
		} = this.props;

		const classes = classNames(
			'input-group-wrapper__3-options',
			className,
			`input-group-${DisplayType.BLOCK}`,
		);

		return (
			<div
				id={id}
				className={classes}
				aria-live="assertive"
			>
				{title ? (
					<h4 className="input-group__3-options-header">
						{title}
					</h4>
				) : null}
				{title && toolTip ? <p>{toolTip}</p> : ''}

				<div className="three-options-input-group">
					{options && options.values ? options.values.map(value => (
						<ThreeOptionsToggleableButton
							id={value.id}
							key={value.id}
							name={value.id}
							model={model}
							modelProperty={value.id}
							label={value.value}
							isReadOnly={isReadOnly}
							isRequired={hasRequiredValidator(validators)}
							onAfterChange={checkValidation}
						/>
					)) : null}
				</div>
			</div>
		);
	}
}
