/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { IQuestionTile, QuestionTileOptionsProps } from 'Forms/Questions/QuestionTile';
import { QuestionType } from 'Forms/Schema/Question';
import TileOptions from '../Options/TileOptions';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
import FormStatementOptions from '../Options/FormStatementOptions';
// % protected region % [Add any further imports here] on begin
import CustomFormStatementOptions from '../Options/CustomFormStatementOptions';
// % protected region % [Add any further imports here] end

@observer
export class FormStatementTileOptions extends Component<QuestionTileOptionsProps> {
	// % protected region % [Add extra options class properties here] off begin
	// % protected region % [Add extra options class properties here] end

	public render() {
		// % protected region % [Customize options render here] on begin
		const { question, schema } = this.props;

		return (
			<>
				<TileOptions
					question={question}
					schema={schema}
					hasShowConditions={false}
				/>
				<CustomFormStatementOptions
					question={question}
					schema={schema}
				/>
			</>
		);
		// % protected region % [Customize options render here] end
	}
}

export interface IFormStatementTileProps<T> extends QuestionComponentProps<T> {
	// % protected region % [Add extra props here] off begin
	// % protected region % [Add extra props here] end
}

@observer
export class FormStatementTile<T> extends QuestionComponent<T, IFormStatementTileProps<T>> implements IQuestionTile {
	// % protected region % [Customize static form vars here] off begin
	static displayName = 'Statement';

	static questionType: QuestionType = 'statement';

	static optionsMenu = FormStatementTileOptions;

	static compareFunction = undefined;

	static validateFunction = undefined;

	static conditionOptions = undefined;

	static validatorOptions = undefined;

	static stylingOptions = [
		{ display: 'Light', value: 'form-statement--light' },
		{ display: 'Important', value: 'form-statement--important' },
	];
	// % protected region % [Customize static form vars here] end

	// % protected region % [Add extra class properties here] off begin
	// % protected region % [Add extra class properties here] end

	public render() {
		// % protected region % [Customize render here] on begin
		const { title, content, className } = this.props;

		return (
			<div className={classNames(className, 'form-statement-wrapper')}>
				<h4 className="question__content">{title}</h4>
				<p className="question__content_inner">{content}</p>
			</div>
		);
		// % protected region % [Customize render here] end
	}
}

// % protected region % [Add extra methods here] off begin
// % protected region % [Add extra methods here] end
