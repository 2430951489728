/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsBaseFormSubmission from 'Models/Security/Acl/VisitorsBaseFormSubmission';
import AdministratorBaseFormSubmission from 'Models/Security/Acl/AdministratorBaseFormSubmission';
import OrganisationAdminBaseFormSubmission from 'Models/Security/Acl/OrganisationAdminBaseFormSubmission';
import SiteUserBaseFormSubmission from 'Models/Security/Acl/SiteUserBaseFormSubmission';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import type { FormVersion } from 'Forms/FormVersion';
import { FormEntityData, SubmissionEntityData } from 'Forms/FormEntityData';
import { jsonReplacerFn } from 'Models/Model';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBaseFormSubmissionEntityAttributes extends IModelAttributes, SubmissionEntityData {

	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BaseFormSubmissionEntity', 'Base Form Submission')
// % protected region % [Customise your entity metadata here] end
export default class BaseFormSubmissionEntity extends Model
	implements IBaseFormSubmissionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBaseFormSubmission(),
		new AdministratorBaseFormSubmission(),
		new OrganisationAdminBaseFormSubmission(),
		new SiteUserBaseFormSubmission(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for attribute 'Submission Data'] off begin
		name: 'Submission Data',
		displayType: 'form-data',
		order: 10,
		headerColumn: false,
		searchable: false,
		// % protected region % [Modify props to the crud options here for attribute 'Submission Data'] end
	})
	public submissionData: {[key: string]: any} = {};

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for attribute 'Form Version'] off begin
		name: 'Form Version',
		displayType: 'reference-combobox',
		order: 20,
		isJoinEntity: true,
		headerColumn: true,
		readFieldType: 'hidden',
		updateFieldType: 'hidden',
		referenceTypeFunc: () => Models.BaseFormEntity,
		displayFunction: (attr, that) => (that as BaseFormSubmissionEntity).formVersion.version.toString(),
		onAfterChange: model => {
			const self = model as BaseFormSubmissionEntity;
			// In this case self.formVersionId will be the same type as the form entity since we have isJoinEntity
			// set to true.
			const formEntity = self.formVersionId as unknown as Models.BaseFormEntity;
			if (formEntity && formEntity.publishedVersionId && formEntity.publishedVersion) {
				self.formVersionId = formEntity.publishedVersionId;
				self.formVersion = formEntity.publishedVersion;
			}
		},
		// % protected region % [Modify props to the crud options here for attribute 'Form Version'] end
	})
	formVersionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	formVersion: FormVersion;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBaseFormSubmissionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBaseFormSubmissionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.submissionData !== undefined) {
				if (typeof attributes.submissionData === 'string') {
					this.submissionData = JSON.parse(attributes.submissionData) as {[key: string]: any};
				} else {
					this.submissionData = attributes.submissionData;
				}
			}
			if (attributes.formVersion !== undefined) {
				this.formVersion = attributes.formVersion;
				if (typeof attributes.formVersion.formData === 'string') {
					this.formVersion.formData = JSON.parse(attributes.formVersion.formData);
				}
			}
			if (attributes.formVersionId !== undefined) {
				this.formVersionId = attributes.formVersionId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		formVersion {
			id
			created
			modified
			formData
			version
			form {
				id
				name
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	// % protected region % [Customize List Expands here] off begin
	public listExpands = `
		formVersion {
			id
			created
			modified
			formData
			version
			form {
				id
				name
			}
		}
	`;
	// % protected region % [Customize List Expands here] end

	/**
	 * The JSON transform function used to transform the submission data before
	 * sending it to the server.
	 */
	private submissionTransform: jsonReplacerFn = input => {
		// % protected region % [Modify submission transform here] off begin
		const { submissionData } = input;
		if (submissionData) {
			input.submissionData = JSON.stringify(submissionData);
		}
		return input;
		// % protected region % [Modify submission transform here] end
	}

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			submissionData: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
				jsonTransformFn: this.submissionTransform,
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BaseFormSubmissionEntity.prototype, 'created');
CRUD(modifiedAttr)(BaseFormSubmissionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
