import React, {
	FC, memo, useState, useCallback, useMemo, useEffect,
} from 'react';
import { Menu } from 'semantic-ui-react';
import cn from 'classnames';
import { Slide } from '../../../Forms/Schema/Question';

export interface TileMenuProps {
	defaultSlide: string;
	onItemClick: (id: string) => any;
	itemFilter: ItemFilter[];
	allSlides: Slide[];
}

export type ItemFilter = {
	label?: string; // for overriding labels
    disabled?: boolean; // for hiding menu options entirely
};

// Used for identifying specific slides from their ID to change how what is displayed
// based on the specific slide + question type
export enum StaticSlides {
	NPS = 'a87bbb16-56a1-4a92-8cba-18f14baa37ec',
	PracticeTeam = 'a23054b7-4fa7-4ae1-be44-c127ad04dac6',
	CareTeam = '5747f499-e41c-4114-8edb-e474cce685e2',
	Advice = '12ac067a-5e72-4c5d-81a4-c5adb38dc29d',
	Kindness = '1d2f295e-377f-4768-949b-1aa0d8886620',
	Feelings = '014ac980-1800-4024-bdcb-ecbb25f3a2c8',
	Respect = '25f80399-6a88-49a3-b9bb-d44f1ae4965f',
	Experience = '41ef8668-ad82-4b74-a6b3-204aadc9d3c3',
	Comments = '0a48a743-3330-4579-8d2d-0b062fa8543f',
	Demographics = 'ad369a50-6f75-430e-b69d-8f8c22763b7b',
	BespokeOne = '1',
	BespokeTwo = '2',
}

type SlideConfig = {
	id: StaticSlides;
	label: string;
	icon: string;
}

export const SlideMap: Record<StaticSlides, SlideConfig> = {
	[StaticSlides.NPS]: {
		id: StaticSlides.NPS,
		label: 'NPS',
		icon: 'chart-line',
	},
	[StaticSlides.PracticeTeam]: {
		id: StaticSlides.PracticeTeam,
		label: 'Practice Team',
		icon: 'person-group',
	},
	[StaticSlides.CareTeam]: {
		id: StaticSlides.CareTeam,
		label: 'Care Team',
		icon: 'activity',
	},
	[StaticSlides.Advice]: {
		id: StaticSlides.Advice,
		label: 'Advice',
		icon: 'information',
	},
	[StaticSlides.Kindness]: {
		id: StaticSlides.Kindness,
		label: 'Kindness',
		icon: 'heart',
	},
	[StaticSlides.Feelings]: {
		id: StaticSlides.Feelings,
		label: 'Feelings',
		icon: 'emoji',
	},
	[StaticSlides.Respect]: {
		id: StaticSlides.Respect,
		label: 'Respect',
		icon: 'check',
	},
	[StaticSlides.Experience]: {
		id: StaticSlides.Experience,
		label: 'Experience',
		icon: 'globe',
	},
	[StaticSlides.Comments]: {
		id: StaticSlides.Comments,
		label: 'Comments',
		icon: 'feedback',
	},
	[StaticSlides.Demographics]: {
		id: StaticSlides.Demographics,
		label: 'Demographics',
		icon: 'chart-bar-3',
	},
	[StaticSlides.BespokeOne]: {
		id: StaticSlides.BespokeOne,
		label: '',
		icon: 'person-group',
	},
	[StaticSlides.BespokeTwo]: {
		id: StaticSlides.BespokeTwo,
		label: '',
		icon: 'chart-bar-3',
	},
};

export const MenuSlidesOrdered: SlideConfig[] = [
	SlideMap[StaticSlides.NPS],
	SlideMap[StaticSlides.PracticeTeam],
	SlideMap[StaticSlides.CareTeam],
	SlideMap[StaticSlides.Advice],
	SlideMap[StaticSlides.Kindness],
	SlideMap[StaticSlides.Feelings],
	SlideMap[StaticSlides.Respect],
	SlideMap[StaticSlides.Experience],
	SlideMap[StaticSlides.Comments],
	SlideMap[StaticSlides.Demographics],
];

const SlideMenu: FC<TileMenuProps> = ({
	defaultSlide, onItemClick, itemFilter, allSlides,
}) => {
	const [activeTile, setTile] = useState<string>(defaultSlide);
	/* Clear all extra the MenuSlides */
	MenuSlidesOrdered.splice(10);

	if (allSlides.length > 10) {
		/* Get Dynamic Slides */
		for (let i = 10; i < allSlides.length; i++) {
			if (i === 10) {
				SlideMap[StaticSlides.BespokeOne].id = allSlides[i].id;
				SlideMap[StaticSlides.BespokeOne].label = allSlides[i].name;
				MenuSlidesOrdered.push(SlideMap[StaticSlides.BespokeOne]);
			} else {
				SlideMap[StaticSlides.BespokeTwo].id = allSlides[i].id;
				SlideMap[StaticSlides.BespokeTwo].label = allSlides[i].name;
				MenuSlidesOrdered.push(SlideMap[StaticSlides.BespokeTwo]);
			}
		}
	}

	const activeOptions = useMemo(() => {
		if (itemFilter.length !== MenuSlidesOrdered.length) {
			console.error('Slide filter and options length mismatch');
		}

		return MenuSlidesOrdered.reduce((acc, curr, i) => {
			return itemFilter[i].disabled
				? acc
				: [
					...acc,
					{
						...curr,
						label: itemFilter[i].label || curr.label,
					},
				];
		}, [] as typeof MenuSlidesOrdered);
	}, [itemFilter]);

	const handleItemClick = useCallback((id: string) => {
		console.groupEnd();
		console.group('TILE SWITCH', activeOptions.find(x => x.id === id)?.label);
		setTile(id);
		onItemClick(id);
	}, [activeOptions, onItemClick]);

	return (
		<Menu className="slide-menu" pointing secondary>
			{
				activeOptions.map((x, i) => (
					<Menu.Item
						key={x.label}
						name={x.label}
						active={activeTile === x.id}
						onClick={() => handleItemClick(x.id)}
					>
						<span className={cn('icon-left', `icon-${x.icon}`)}>
							{x.label}
						</span>
					</Menu.Item>
				))
			}
		</Menu>
	);
};

export default memo(SlideMenu);
