import React from 'react';
import Navigation, { Orientation } from '../Components/Navigation/Navigation';
import { getFrontendNavLinks } from '../FrontendNavLinks';
import SecuredPage from '../Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import useAsync from '../../Hooks/useAsync';
import { OrganisationEntity } from '../../Models/Entities';
import { store } from '../../Models/Store';
import Spinner from '../Components/Spinner/Spinner';
import alertToast from 'Util/ToastifyUtils';

const ViewOrganisationPage = (props: RouteComponentProps) => {
	const {
		staticContext,
		match,
		location,
		history,
	} = props;

	const { type, error, data } = useAsync<(OrganisationEntity | undefined)[]>(() => {
		if (store.userOrganisationId) {
			return OrganisationEntity.fetch<OrganisationEntity | undefined>({
				args: [
					[{ path: 'id', comparison: 'equal', value: store.userOrganisationId }],
				],
			});
		}
		return new Promise<(OrganisationEntity | undefined)[]>(r => r([]));
	}, [store.userOrganisationId]);

	if (type === 'loading') {
		return <Spinner />;
	}

	if (type === 'error') {
		return <div>{error.response?.data}</div>;
	}

	const organisation = data[0];

	if (type === 'data' && !organisation) {
		alertToast(
			// eslint-disable-next-line max-len
			'No organisation found - please contact your Organisation Admin and confirm you are assigned to an Organisation',
			'error',
		);
	}

	return (
		<SecuredPage groups={['OrganisationAdmin', 'SiteUser']}>
			<Navigation
				linkGroups={getFrontendNavLinks(props)}
				orientation={Orientation.VERTICAL}
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>
			<div className="body-content">
				<h1 className="body-header">
					{organisation?.name}
				</h1>
				<div className="crud-component">
					<h3>Organisation Administrators</h3>
					<section
						className="collection-component"
						style={{
							paddingLeft: 0,
							paddingRight: 0,
						}}
					>
						<section
							className="collection__list"
						>
							<table>
								<thead>
									<tr className="list__header">
										<th>Name</th>
										<th>Email</th>
										<th>Contact number</th>
									</tr>
								</thead>
								<tbody>
									{organisation?.organisationAdmins.map(admin => (
										<tr key={admin.id}>
											<td>{`${admin.firstName} ${admin.lastName}`}</td>
											<td>{admin.email}</td>
											<td>{admin.contactNumber}</td>
										</tr>
									))}
								</tbody>
							</table>
						</section>
					</section>
				</div>
			</div>
		</SecuredPage>
	);
};

export default ViewOrganisationPage;
