import * as React from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { Button } from 'Views/Components/Button/Button';
import { Label } from 'semantic-ui-react';

export interface IFormSidebarAccordionConfig {
	name: string;
	component: React.ReactNode;
	key: string;
	afterTitleContent?: React.ReactNode;
	disabled?: boolean;
	slideDisabled?: boolean;
}

export interface IFormSidebarAccordionGroupProps {
	accordions: Array<IFormSidebarAccordionConfig>;
}

@observer
export class FormSidebarAccordionSection extends React.Component<IFormSidebarAccordionConfig> {
	@observable
	private showContents = false;

	@computed
	private get isOpen() {
		const { disabled } = this.props;
		return this.showContents && !disabled;
	}

	@computed
	private get contentClassName() {
		return this.isOpen
			? 'accordion__info accordion__info--expanded'
			: 'accordion__info accordion__info--collapsed';
	}

	@action
	public toggleContents = (isOpen?: boolean) => {
		if (isOpen === undefined) {
			this.showContents = !this.showContents;
		} else {
			this.showContents = isOpen;
		}
	}

	public render() {
		const {
			afterTitleContent, component, name, slideDisabled,
		} = this.props;

		return (
			<section className={this.isOpen ? 'accordion active' : 'accordion'}>
				<Button
					icon={{ icon: 'chevron-up', iconPos: 'icon-right' }}
					onClick={() => this.toggleContents()}
					buttonProps={{
						style: !afterTitleContent ? { maxWidth: 'none' } : {},
					}}
				>
					<span>
						{name}
						{slideDisabled
							? <Label style={{ lineHeight: 'normal', marginLeft: '10px' }} horizontal>Hidden</Label>
							: null}
					</span>
				</Button>
				{afterTitleContent}
				<div className={this.contentClassName}>
					{this.isOpen ? component : null}
				</div>
			</section>
		);
	}
}

export default function FormSidebarAccordionGroup(props: IFormSidebarAccordionGroupProps) {
	const { accordions } = props;

	return (
		<>
			{accordions.map(accordion => <FormSidebarAccordionSection {...accordion} />)}
		</>
	);
}
