import { Question, QuestionType } from 'Forms/Schema/Question';

/**
 * Question Types determine Chart Type
 * Chart Type (and sometimes Question options.type) determines Query Type
 */

// React Chart component types
export enum ChartType {
	// percent charts
	NPS = 'nps-chart',
	Rating = 'rating-chart',

	// entity count charts
	CommentCount = 'comment-count',
	SubmissionCount = 'submission-count',
	CommentList = 'commment-list',

	// value highlight charts
	RadioButtonCount = 'radio-button-count',
	MultiRadioAggregate = 'multi-radio-aggregate',
	StackedRadioAggregate = 'stacked-radio-aggregate',

	// bar charts
	RadioButtonBar = 'radio-button-bar',
	StackedBar = 'stacked-bar-chart',

	// line charts
	NPSLine = 'nps-line',
	RatingLine = 'rating-line',

	// column charts
	MultiCheckboxColumn = 'multi-checkbox-column',
}

// Backend query types
export enum QueryType {
	ScaleNPS = 'scale-nps',
	ScaleRating = 'scale-rating',

	CommentCount = 'comment-count',
	CommentList = 'comment-list',
	EntityCount = 'entity-count',

	RadioOptionCount = 'radio-option-count',
	ThreeOptionCount = '3-option-count',

	ScaleRatingSum = 'scale-rating-sum',

	CheckboxCount = 'checkbox-count',
}

// Map Chart Types to the Question type that determines whether they are present
//
// 'undefined' signifies the query does not target a specific question type and is therefore always visible in the Dashboard
export const QuestionTypeMap: Record<ChartType, QuestionType | undefined> = {
	[ChartType.NPS]: 'scale',
	[ChartType.Rating]: 'scale',

	[ChartType.CommentCount]: 'textArea',
	[ChartType.SubmissionCount]: undefined,
	[ChartType.CommentList]: 'textArea',

	[ChartType.RadioButtonCount]: 'radiobutton',
	[ChartType.MultiRadioAggregate]: 'radiobutton',
	[ChartType.StackedRadioAggregate]: '3-options',

	[ChartType.RadioButtonBar]: 'radiobutton',
	[ChartType.StackedBar]: '3-options',

	[ChartType.NPSLine]: 'scale',
	[ChartType.RatingLine]: 'scale',

	[ChartType.MultiCheckboxColumn]: 'checkbox-pill',
};

// Map React chart types to query types
export const QueryTypeMap: Record<ChartType, QueryType> = {
	[ChartType.NPS]: QueryType.ScaleNPS,
	[ChartType.Rating]: QueryType.ScaleRating,

	[ChartType.CommentCount]: QueryType.CommentCount,
	[ChartType.SubmissionCount]: QueryType.EntityCount,
	[ChartType.CommentList]: QueryType.CommentList,

	[ChartType.RadioButtonCount]: QueryType.RadioOptionCount,
	[ChartType.MultiRadioAggregate]: QueryType.RadioOptionCount,
	[ChartType.StackedRadioAggregate]: QueryType.ThreeOptionCount,

	[ChartType.RadioButtonBar]: QueryType.RadioOptionCount,
	[ChartType.StackedBar]: QueryType.ThreeOptionCount,

	[ChartType.NPSLine]: QueryType.ScaleNPS,
	[ChartType.RatingLine]: QueryType.ScaleRatingSum,

	[ChartType.MultiCheckboxColumn]: QueryType.CheckboxCount,
};

export const SlideMap = {

};

export const determineScaleType = (question: Question, chartType: ChartType): boolean => {
	if (question.options?.type === 'rating') return [ChartType.Rating, ChartType.RatingLine].includes(chartType);
	if (question.options?.type === 'nps') return [ChartType.NPS, ChartType.NPSLine].includes(chartType);

	// shouldn't reach here yo
	throw new Error('Chart type for Scale question subtype could not be determined');
};

export enum LoadingState {
	Init = 'not-started',
	InProgress = 'in-progress',
	Done = 'done',
	Failed = 'failed',
}

export enum TimeInterval {
	Day = 'day',
	Week = 'week',
	Month = 'month',
}

interface IChartProps {
	organisationId?: string,
	siteId?: string,
	startDate: Date,
	endDate: Date,
	className?: string,
}

export interface IMultiChartProps extends IChartProps {
	questionIds: string[],
}

export interface ISingleChartProps extends IChartProps {
	questionId: string,
}

export type ChartFilters = {
	gender: string | undefined;
	age: string | undefined;
	language: string | undefined;
	visits: string | undefined;
}

export interface IFetchParams<T> {
	(
		questionIds: string[], organisationId: string | undefined, siteId: string | undefined,
		startDate: Date, endDate: Date, interval?: TimeInterval, skip?: number, take?: number, filters?: ChartFilters,
	): Promise<T>;
}

export type RawRadioOptionData = {
	count: number;
	option: string;
}

export type RawThreeRadioData = {
	count: number;
	questionCount: Record<string, number>[];
}

// [hex, metric-highlight-chart highlight classname]
export const barColors = {
	green: { /* Secondary/Secondary 500 */
		hex: '#8CC342',
		class: 'green-highlight',
	},
	blue: { /* Primary/Primary 500 */
		hex: '#26A9E0',
		class: 'blue-highlight',
	},
	grey: { /* Disabled/Disabled 500 */
		hex: '#7B7B7B',
		class: 'grey-highlight',
	},
	orange: { /* Warning/Warning 500 */
		hex: '#FFBC39',
		class: 'orange-highlight',
	},
	red: { /* Error/Error 500 */
		hex: '#F6464F',
		class: 'red-highlight',
	},
	purple: { /* Error/Error 500 */
		hex: '#8623DF',
		class: 'red-highlight',
	},
};

/**
 * These enums are used for mapping radio options to colors in getColorForOption
 * The casing does not reflect the casing used in the scheme and are
 * therefore appropriate for lowercase comparisons only for identifying radio options
 * NOT for labels to be displayed to the user
 */
enum AdviceOptions {
	Yes = 'yes',
	No = 'no',
	Unsure = 'unsure',
}

enum KindnessOptons {
	BestEver = 'best ever',
	Good = 'good',
	Fair = 'fair',
	Poor = 'poor',
	VeryPoor = 'very poor',
}

enum FeelingsOptions {
	Neutral = 'neutral',

	Relieved = 'relieved',
	SomewhatRelieved = 'somewhat relieved',
	SomewhatWorried = 'somewhat worried',
	Worried = 'worried',

	Hopeful = 'hopeful',
	SomewhatHopeful = 'somewhat hopeful',
	SomewhatHopeless = 'somewhat hopeless',
	Hopeless = 'hopeless',

	Connected = 'connected',
	SomewhatConnected = 'somewhat connected',
	SomewhatLonely = 'somewhat lonely',
	Loneley = 'lonely',

	Confident = 'confident',
	SomewhatConfident = 'somewhat confident',
	SomewhatConfused = 'somewhat confused',
	Confused = 'confused',

	Joyful = 'joyful',
	SomewhatJoyful = 'somewhat joyful',
	SomewhatAngry = 'somewhat angry',
	Angry = 'angry',
}

export enum EmotionWeight {
	Positive = 2,
	SomewhatPositive = 1,
	Neutral = 0,
	SomewhatNegative = -1,
	Negative = -2,
}

export const getFeelingWeighting = (option: string) => {
	switch (option?.toLowerCase()) {
		case AdviceOptions.Yes:
		case KindnessOptons.BestEver:
		case FeelingsOptions.Relieved:
		case FeelingsOptions.Hopeful:
		case FeelingsOptions.Connected:
		case FeelingsOptions.Confident:
		case FeelingsOptions.Joyful:
			return EmotionWeight.Positive;

		case KindnessOptons.Good:
		case FeelingsOptions.SomewhatRelieved:
		case FeelingsOptions.SomewhatHopeful:
		case FeelingsOptions.SomewhatConnected:
		case FeelingsOptions.SomewhatConfident:
		case FeelingsOptions.SomewhatJoyful:
			return EmotionWeight.SomewhatPositive;

		case AdviceOptions.Unsure:
		case KindnessOptons.Fair:
		case FeelingsOptions.Neutral:
			return EmotionWeight.Neutral;

		case KindnessOptons.Poor:
		case FeelingsOptions.SomewhatWorried:
		case FeelingsOptions.SomewhatHopeless:
		case FeelingsOptions.SomewhatLonely:
		case FeelingsOptions.SomewhatConfused:
		case FeelingsOptions.SomewhatAngry:
			return EmotionWeight.SomewhatNegative;

		case AdviceOptions.No:
		case KindnessOptons.VeryPoor:
		case FeelingsOptions.Worried:
		case FeelingsOptions.Hopeless:
		case FeelingsOptions.Loneley:
		case FeelingsOptions.Confused:
		case FeelingsOptions.Angry:
			return EmotionWeight.Negative;
		default:
			return undefined;
	}
};

// Map colors to options in a really verbose way
export const getColorForOption = (option: string) => {
	switch (option?.toLowerCase()) {
		case AdviceOptions.Yes:
		case KindnessOptons.BestEver:
		case FeelingsOptions.Relieved:
		case FeelingsOptions.Hopeful:
		case FeelingsOptions.Connected:
		case FeelingsOptions.Confident:
		case FeelingsOptions.Joyful:
			return barColors.green;

		case KindnessOptons.Good:
		case FeelingsOptions.SomewhatRelieved:
		case FeelingsOptions.SomewhatHopeful:
		case FeelingsOptions.SomewhatConnected:
		case FeelingsOptions.SomewhatConfident:
		case FeelingsOptions.SomewhatJoyful:
			return barColors.blue;

		case AdviceOptions.Unsure:
		case KindnessOptons.Fair:
		case FeelingsOptions.Neutral:
			return barColors.grey;

		case KindnessOptons.Poor:
		case FeelingsOptions.SomewhatWorried:
		case FeelingsOptions.SomewhatHopeless:
		case FeelingsOptions.SomewhatLonely:
		case FeelingsOptions.SomewhatConfused:
		case FeelingsOptions.SomewhatAngry:
			return barColors.orange;

		case AdviceOptions.No:
		case KindnessOptons.VeryPoor:
		case FeelingsOptions.Worried:
		case FeelingsOptions.Hopeless:
		case FeelingsOptions.Loneley:
		case FeelingsOptions.Confused:
		case FeelingsOptions.Angry:
			return barColors.red;
		default:
			return undefined;
	}
};
