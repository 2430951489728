import React, { useEffect } from 'react';
import { Button } from '../../Components/Button/Button';

interface IPublicSubmissionEndScreenFooter {
	backToStartOnClick: () => void
}

let autoBackToStartTimer: NodeJS.Timeout | undefined;

const PublicSubmissionEndScreenFooter = (props: IPublicSubmissionEndScreenFooter) => {
	const { backToStartOnClick } = props;

	useEffect(() => {
		if (!autoBackToStartTimer) {
			autoBackToStartTimer = setTimeout(() => {
				backToStartOnClick();
			}, 5000);
		}

		return () => {
			if (autoBackToStartTimer) {
				clearTimeout(autoBackToStartTimer);
				autoBackToStartTimer = undefined;
			}
		};
	}, [backToStartOnClick]);

	return (
		<>
			<p className="txt-subtitle-3">
				All intellectual property rights and proprietary rights in this product
				including the software, presentations, slides and documentation, are owned by
				Client Focused Evaluation Program (CFEP) Surveys. The features, functions,
				graphics and source code of the software cannot be copied, reproduced or reverse
				engineered by any means (electronic, mechanical, photocopy, recording or otherwise)
				without the express prior written permission of CFEP Surveys.
			</p>
			<Button
				className="icon-undo icon-right btn--solid"
				onClick={() => backToStartOnClick()}
			>
				Back to Start
			</Button>
		</>
	);
};

export default PublicSubmissionEndScreenFooter;
