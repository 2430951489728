/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Form, Question, Slide } from './Schema/Question';
import { getQuestion } from './Questions/QuestionUtils';

// % protected region % [Modify the slide tile here] on begin
export interface ISlideTileProps<T> extends Slide {
	model: T,
	schema: Form;
	isReadOnly?: boolean;
	className?: string;
	disableShowConditions?: boolean;
	beforeQuestionContent?: (question: Question, slide: Slide, index: number) => React.ReactNode;
	afterQuestionContent?: (question: Question, slide: Slide, index: number) => React.ReactNode;
	beforeSlideContent?: (slide: Slide) => React.ReactNode;
	afterSlideContent?: (slide: Slide) => React.ReactNode;
	reValidate?: boolean;
	isBaseFormSlide: boolean;
}

@observer
export class SlideTile<T> extends React.Component<ISlideTileProps<T>> {
	public render() {
		const {
			afterQuestionContent,
			afterSlideContent,
			beforeQuestionContent,
			beforeSlideContent,
			className,
			contents,
			disableShowConditions,
			isReadOnly,
			model,
			name,
			reValidate,
			schema,
		} = this.props;
		return (
			<div className={classNames('form-slide', className)}>
				{beforeSlideContent ? beforeSlideContent(this.props) : undefined}
				<h3>{name}</h3>
				{contents.map((question, i) => (
					<div
						className="form__question-container"
						id={question.id}
						key={question.id}
						data-name={question.title}
					>
						{beforeQuestionContent ? beforeQuestionContent(question, this.props, i) : undefined}
						{getQuestion({
							schema: schema,
							model: model,
							isReadOnly: isReadOnly,
							disableShowConditions: disableShowConditions,
							reValidate: reValidate,
							...question,
						})}
						{afterQuestionContent ? afterQuestionContent(question, this.props, i) : undefined}
					</div>
				))}
				{afterSlideContent ? afterSlideContent(this.props) : undefined}
			</div>
		);
	}
}
// % protected region % [Modify the slide tile here] end
