import * as React from 'react';
import classNames from 'classnames';
import InputWrapper, { LabelPositions, InputType } from '../Inputs/InputWrapper';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { DisplayType } from '../Models/Enums';
import * as UUID from 'uuid';
import { Combobox, ComboboxOption } from '../Combobox/Combobox';
import If from '../If/If';
import classnames from 'classnames';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';

export interface IScaleQuestionProps<T> {
	id: string;
	model: T;
	modelProperty: string;
	className?: string;
	name?: string;
	displayType?: DisplayType;
	label?: React.ReactNode;
	tooltip?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	innerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
	options: Array<ComboboxOption<string>>;
	errors?: string | string[];
	/** Action to perform after the onChange method is called */
	onAfterChange?: (value: string) => void;
	/**
	 * Should the key for the fields be random uuids. This is useful for when there is a chance for non unique values.
	 * If this is not set or is false then the option field is used as the key.
	 */
	uuidKey?: boolean;
	minValueDescription: string;
	maxValueDescription: string;
}

export const ScaleQuestion = observer(<T, >(props: IScaleQuestionProps<T>) => {
	const { isMobile } = useDeviceDetect();

	const {
		tooltip,
		isDisabled,
		isReadOnly,
		isRequired,
		label,
		modelProperty,
		id,
		innerProps,
		model,
		uuidKey,
		name,
		options,
		className,
		minValueDescription,
		maxValueDescription,
		onAfterChange,
	} = props;

	const classes = classNames(
		className,
		'input-group-wrapper__scale',
	);

	const tooltipId = `${id}-tooltip`;
	const requiredMark = (label && isRequired) ? <span className="required">*</span> : undefined;
	const labelNode = label
		? (
			<h4
				className="input-group__scale-header"
				aria-describedby={tooltip ? tooltipId : undefined}
			>
				{label}{requiredMark}
			</h4>
		)
		: null;
	const tooltipNode = (label && tooltip) ? <p id={tooltipId}>{tooltip}</p> : '';
	const groupName = name;

	if (innerProps) {
		innerProps['aira-live'] = 'assertive';
	}

	const onChecked = action((event: React.ChangeEvent<HTMLInputElement>) => {
		model[modelProperty] = event.target.value;
		onAfterChange?.(event.target.value);
	});

	return (
		<div id={id} className={classes}>
			<div>
				{labelNode}
				{tooltipNode}
			</div>
			<div className="input-group-options-wrapper__scale">
				<div className="input-group-options-values__scale">
					<If condition={isMobile}>
						<div className="mobile">
							<div>
								<Combobox
									className="scale-combobox"
									label="Your rating"
									options={options}
									modelProperty={modelProperty}
									model={model}
									searchable={false}
								/>
								<p className="txt-sm-body">0 to 6 being very poor and 9 to 10 being excellent</p>
							</div>
							{model[modelProperty] && (
								<div className={classnames(
									'score',
									{ poor: model[modelProperty] <= 6 },
									{ excellent: model[modelProperty] >= 9 },
								)}
								>
									<span>{model[modelProperty]}</span>
								</div>
							)}
						</div>
					</If>
					<If condition={!isMobile}>
						{options.map(option => (
							<InputWrapper
								inputType={InputType.RADIO}
								key={uuidKey ? UUID.v4() : option.value}
								label={{ text: option.display, position: LabelPositions.AFTER }}
								inputId={`${id}-${option.value}`}
							>
								<input
									type="radio"
									name={groupName}
									id={`${id}-${option.value}`}
									value={option.value}
									checked={model[modelProperty] === option.value}
									key={uuidKey ? UUID.v4() : option.value}
									onChange={onChecked}
									disabled={isDisabled || isReadOnly}
								/>
							</InputWrapper>
						))}
					</If>
				</div>
				<If condition={!isMobile}>
					<div className="input-group-options-description__scale">
						<h6>
							{minValueDescription}
						</h6>
						<h6>
							{maxValueDescription}
						</h6>
					</div>
				</If>
			</div>
		</div>
	);
});
