import React, { useState } from 'react';
import classNames from 'classnames';
import InputWrapper, { LabelPositions, InputType } from '../Inputs/InputWrapper';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { DisplayType } from '../Models/Enums';
import * as uuid from 'uuid';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';
import { TextField } from '../TextBox/TextBox';
import If from '../If/If';

export enum ButtonAlignment {
	VERTICAL = 'radio-group--vertical',
	HORIZONTAL = 'radio-group--horizontal',
}

export interface IRadioButtonGroupQuestionProps<T> {
	id: string;
	model: T;
	modelProperty: string;
	className?: string;
	name: string;
	alignment?: ButtonAlignment;
	displayType?: DisplayType;
	label?: React.ReactNode;
	tooltip?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	innerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
	options: Array<{ value: string, display: string }>;
	errors?: string | string[];
	/** Action to perform after the onChange method is called */
	onAfterChange?: (value: string) => void;
	/**
	 * Should the key for the fields be random uuids. This is useful for when there is a chance for non unique values.
	 * If this is not set or is false then the option field is used as the key.
	 */
	uuidKey?: boolean;
	freeTextOption: {enableFreeText: boolean, freeTextOptionTitle: string};
}

export const RadioButtonGroupQuestion = observer(<T, >(props: IRadioButtonGroupQuestionProps<T>) => {
	const { isMobile } = useDeviceDetect();
	const [isFreeTextOptionSelected, setIsFreeTextOptionSelected] = useState(false);
	const {
		displayType,
		tooltip,
		isDisabled,
		isReadOnly,
		isRequired,
		errors,
		label,
		modelProperty,
		id,
		innerProps,
		alignment,
		model,
		uuidKey,
		name,
		options,
		className,
		freeTextOption,
	} = props;

	const classes = classNames(
		!isMobile ? className : 'radio-group--vertical',
		'radio-question',
		'input-group-wrapper__radio',
		alignment,
	);

	const tooltipId = `${id}-tooltip`;
	const requiredMark = (label && isRequired) ? <span className="required">*</span> : undefined;
	const labelNode = label
		? (
			<h4
				className="input-group__radio-header"
				aria-describedby={tooltip ? tooltipId : undefined}
			>
				{label}{requiredMark}
			</h4>
		)
		: null;
	const tooltipNode = (label && tooltip) ? <p id={tooltipId}>{tooltip}</p> : '';
	const groupName = name;

	if (innerProps) {
		innerProps['aira-live'] = 'assertive';
	}

	const onChecked = action((event: React.ChangeEvent<HTMLInputElement>) => {
		setIsFreeTextOptionSelected(false);
		props.model[props.modelProperty] = event.target.value;
		props.onAfterChange?.(event.target.value);
	});

	const onFreeTextOptionChecked = action((event: React.ChangeEvent<HTMLInputElement>) => {
		setIsFreeTextOptionSelected(prevState => {
			runInAction(() => {
				if (!prevState) {
					props.model[props.modelProperty] = '';
				}
			});

			return true;
		});
	});

	return (
		<InputWrapper
			isInputGroup
			wrapperId={id}
			errors={errors}
			isRequired={isRequired}
			id={id}
			className={classes}
			displayType={displayType}
			innerProps={innerProps}
		>
			{labelNode}
			{tooltipNode}
			<div className="radio-options-wrapper">
				{options.map(option => (
					<InputWrapper
						inputType={InputType.RADIO}
						key={uuidKey ? uuid.v4() : option.value}
						label={{ text: option.display, position: LabelPositions.AFTER }}
						inputId={id + option.value}
					>
						<input
							type="radio"
							name={groupName}
							id={id + option.value}
							value={option.value}
							checked={model[modelProperty] === option.value}
							key={uuidKey ? uuid.v4() : option.value}
							onChange={onChecked}
							disabled={isDisabled || isReadOnly}
						/>
					</InputWrapper>
				))}
				{freeTextOption.enableFreeText ? (
					<>
						<InputWrapper
							inputType={InputType.RADIO}
							label={{ text: freeTextOption.freeTextOptionTitle, position: LabelPositions.AFTER }}
							inputId={`${id}free-text-option`}
						>
							<input
								type="radio"
								name={groupName}
								id={`${id}free-text-option`}
								value={freeTextOption.freeTextOptionTitle}
								checked={isFreeTextOptionSelected}
								onChange={onFreeTextOptionChecked}
								disabled={isDisabled || isReadOnly}
							/>
						</InputWrapper>
						<If condition={isFreeTextOptionSelected}>
							<div style={{
								width: 'auto',
								maxWidth: '768px',
							}}
							>
								<TextField
									inputProps={{
										style: {
											backgroundColor: 'white',
											minWidth: 'unset',
										},
									}}
									model={model}
									modelProperty={modelProperty}
								/>
							</div>
						</If>
					</>
				) : null}
			</div>
		</InputWrapper>
	);
});
