/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import FormsBuildPage, { FormsBuildPageParams } from 'Views/Pages/Admin/Forms/FormsBuildPage';
import FormsLandingPage from 'Views/Pages/Admin/Forms/FormsLandingPage';
// % protected region % [Add any further imports here] on begin
import EditFormsDesigner from '../../EditFormsDesigner';
// % protected region % [Add any further imports here] end

export default class FormsPage extends React.Component<RouteComponentProps> {
	// % protected region % [Add extra class fields here] off begin
	// % protected region % [Add extra class fields here] end

	// % protected region % [Customise the render function here] on begin
	public render() {
		const { match } = this.props;

		const formsBuildPage = (pageProps: RouteComponentProps<FormsBuildPageParams>) => {
			return (
				<EditFormsDesigner
					id={pageProps.match.params.id}
					entity={pageProps.match.params.entity}
				/>
			);
		};

		const formsLandingPage = (pageProps: RouteComponentProps) => {
			return (
				<FormsLandingPage {...pageProps} />
			);
		};

		return (
			<div>
				<Switch>
					<Route exact path={`${match.url}`} render={formsLandingPage} />
					<Route path={`${match.url}/build/:entity/:id`} render={formsBuildPage} />
				</Switch>
			</div>
		);
	}
	// % protected region % [Customise the render function here] end
}
