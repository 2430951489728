import { Form, Question } from '../../Schema/Question';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button, Colors, Display } from '../../../Views/Components/Button/Button';
import { action } from 'mobx';
import { TextField } from '../../../Views/Components/TextBox/TextBox';

interface PillQuestionOptionProps {
	schema: Form,
	question: Question,
}

@observer
export default class PillQuestionOptions extends Component<PillQuestionOptionProps> {
	@action
	public addOption = () => {
		const { question } = this.props;

		if (question.options) {
			const allOptionIndexes: number[] = question.options.values
				.map((value: {value: string, id: string}) => parseInt(value.id.split('-')[5], 10));

			const optionId: number = Math.max(...allOptionIndexes, 0) + 1;

			question.options.values.push({
				value: `Option ${optionId}`,
				id: `${question.id}-${optionId}`,
			});
		}
	}

	@action
	public removeOption = (index: number) => {
		const { question } = this.props;

		if (question.options && question.options.values) {
			question.options.values.splice(index, 1);
		}
	}

	render() {
		const { question } = this.props;
		return (
			<>
				<h4>Selectable options</h4>
				{question.options && question.options.values ? question.options.values.map((option: {
					value: string,
					id: string
				}, i: number) => (
					<React.Fragment key={option.id}>
						<TextField
							model={option}
							modelProperty="value"
							sideButton={(
								<Button
									colors={Colors.Primary}
									display={Display.Solid}
									onClick={() => this.removeOption(i)}
								>
									Remove
								</Button>
							)}
						/>
					</React.Fragment>
				)) : null}
				<Button
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={this.addOption}
				>
					Add new option
				</Button>
				<hr className="sidebar-separator" />
			</>
		);
	}
}
