import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import {
	action, computed, observable, runInAction,
} from 'mobx';
import * as Models from '../../../../../Models/Entities';
import { BaseFormEntity, CustomFormVersionEntity } from '../../../../../Models/Entities';
import SecuredPage from '../../../../Components/Security/SecuredPage';
import Spinner from '../../../../Components/Spinner/Spinner';
import { FormEntityDesigner } from '../../../../../Forms/Designer/FormEntityDesigner';
import { IFetchArgs, Model } from '../../../../../Models/Model';
import { FormEntityData, SubmissionEntityData } from '../../../../../Forms/FormEntityData';
import { Form } from '../../../../../Forms/Schema/Question';
import { FormVersion } from '../../../../../Forms/FormVersion';
import alertToast from '../../../../../Util/ToastifyUtils';
import { store } from '../../../../../Models/Store';
import { mergeBaseFormAndCustomFormVersionFormData } from '../../../../../Forms/FormUtils';

class OrganisationCustomFormEntity extends CustomFormVersionEntity implements FormEntityData {
	@observable
	name: string;

	@observable
	publishedVersionId: string;

	@observable
	formVersions: FormVersion[] = [];

	constructor(customFormVersionEntity: CustomFormVersionEntity) {
		super(customFormVersionEntity);

		runInAction(() => {
			this.publishedVersionId = customFormVersionEntity.id;
			this.formVersions = [
				{
					id: customFormVersionEntity.id,
					formData: JSON.parse(customFormVersionEntity.formdata),
					version: 1,
					formSubmissions: [],
				},
			];
		});
	}

	getAllVersions(includeSubmissions?: boolean, conditions?: IFetchArgs<Record<string, unknown>>): Promise<Form[]> {
		return Promise.resolve([]);
	}

	getPublishedVersion(includeSubmissions: boolean | undefined): Promise<Form | undefined> {
		return Promise.resolve(undefined);
	}

	getSubmissionEntity(): { new(): (Model & SubmissionEntityData) } {
		return Models.SubmissionEntity;
	}
}

type FormEntityModel = Model & FormEntityData;

type OrganisationEntityEditFormPageParams = {
	customFormId: string,
};

@observer
// eslint-disable-next-line max-len
export default class OrganisationEntityEditFormPage extends React.Component<RouteComponentProps<OrganisationEntityEditFormPageParams>> {
	@observable
	private entity: FormEntityModel;

	@observable
	private loadingState: 'loading' | 'error' | 'done' = 'loading';

	@observable
	private errors: Record<string, unknown>;

	@observable
	private customFormEntity?: CustomFormVersionEntity

	@action
	private updateFormSchema = (entity?: OrganisationCustomFormEntity, errors?: Record<string, unknown>) => {
		if (entity) {
			this.entity = entity;
			this.loadingState = 'done';
		} else {
			this.errors = errors || {};
			this.loadingState = 'error';
		}
	};

	@computed
	private get versionId(): string {
		const { match } = this.props;
		return match.params.customFormId;
	}

	@action
	private compareWithBaseForm = (customFormVersion: CustomFormVersionEntity) => {
		BaseFormEntity.fetch<BaseFormEntity>({
			args: [
				[{ path: 'id', comparison: 'equal', value: customFormVersion.baseFormId }],
			],
		}).then(res => {
			if (res.length === 0) {
				throw new Error('Cannot find base form');
			}

			const publishedBaseForm = res[0].publishedVersion;

			if (publishedBaseForm) {
				this.customFormEntity = customFormVersion;
				const formEntityModel = new OrganisationCustomFormEntity(customFormVersion);

				customFormVersion.formVersionId = publishedBaseForm.id;
				formEntityModel.formVersions[0].formData = mergeBaseFormAndCustomFormVersionFormData(
					publishedBaseForm.formData,
					formEntityModel.formVersions[0].formData,
				);

				this.updateFormSchema(formEntityModel);
			} else {
				throw new Error('Base form is not published');
			}
		}).catch(e => {
			alertToast(e.toString(), 'error');
			store.routerHistory.goBack();
		});
	}

	public componentDidMount(): void {
		CustomFormVersionEntity.fetch<CustomFormVersionEntity>(
			{
				args: [
					[{ path: 'id', comparison: 'equal', value: this.versionId }],
				],
			},
		).then((data: CustomFormVersionEntity[]) => {
			this.compareWithBaseForm(data[0]);
		}).catch((e: any) => {
			this.updateFormSchema(undefined, e);
		});
	}

	@action
	private onSave = () => {
		if (this.entity.formVersions && this.customFormEntity) {
			const newFormData = JSON.stringify(this.entity.formVersions[0].formData);

			this.customFormEntity.formdata = newFormData;
			this.customFormEntity.save()
				.then(() => {
					alertToast('Form is saved', 'success');
					store.routerHistory.goBack();
				}).catch(() => {
					alertToast('Error saving form', 'error');
				});
		}
	}

	private renderContents = () => {
		switch (this.loadingState) {
			case 'loading': return <Spinner />;
			case 'error': return `Something went wrong with loading the data. ${JSON.stringify(this.errors)}`;
			case 'done': return (
				<FormEntityDesigner
					form={this.entity}
					onSaveDraft={() => {}}
					onSavePublish={this.onSave}
				/>
			);
		}
	}

	public render() {
		return (
			<SecuredPage>
				<div className="body-content">
					{this.renderContents()}
				</div>
			</SecuredPage>
		);
	}
}
