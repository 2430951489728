import * as React from 'react';
import { useState } from 'react';
import { FormEntity } from 'Models/Entities';
import { RouteComponentProps } from 'react-router-dom';
import { SERVER_URL } from 'Constants';
import useAsync from '../../Hooks/useAsync';
import axios from 'axios';
import Spinner from '../Components/Spinner/Spinner';
import { observer } from 'mobx-react';
import PublicSubmissionForm from './PublicSubmission/PublicSubmissionForm';
import { SubmitFormProgressBarContextProvider } from '../Components/ProgressBar/SubmitFormProgressBarContext';

export interface PublicSubmissionPageProps extends RouteComponentProps<{ slug: string }> {
}

const PublicSubmissionPage = observer((props: PublicSubmissionPageProps) => {
	const [submissionState, setSubmissionState] = useState<'start' | 'form' | 'end'>('start');
	const { match, history, location } = props;

	const { type, error, data } = useAsync(() => axios
		.get<FormEntity>(`${SERVER_URL}/api/entity/FormEntity/public/${match.params.slug}`)
		.then(res => {
			return new FormEntity(res.data);
		}),
	[match.params.slug]);

	if (type === 'loading') {
		return <Spinner />;
	}

	if (type === 'error') {
		return <div>{error.response.data}</div>;
	}

	return (
		<SubmitFormProgressBarContextProvider>
			<PublicSubmissionForm
				data={data}
				submissionState={submissionState}
				setSubmissionState={setSubmissionState}
			/>
		</SubmitFormProgressBarContextProvider>
	);
});

export default PublicSubmissionPage;
