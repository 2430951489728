import * as React from 'react';
import * as Models from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Spinner from 'Views/Components/Spinner/Spinner';
import {
	action, computed, observable, runInAction,
} from 'mobx';
import { observer } from 'mobx-react';
import { FormEntityData } from 'Forms/FormEntityData';
import { Model } from 'Models/Model';
import { FormEntityDesigner } from 'Forms/Designer/FormEntityDesigner';
import { store } from 'Models/Store';
import { CustomFormVersionEntity, FormEntity } from 'Models/Entities';
import alertToast from '../../Util/ToastifyUtils';
import { mergeCustomFormVersionAndSiteForm } from '../../Forms/FormUtils';

type FormEntityModel = Model & FormEntityData;

export type EditFormDesignerParams = {
	entity: string,
	id: string,
};

@observer
export default class EditFormsDesigner extends React.Component<EditFormDesignerParams> {
	@observable
	private entity: FormEntityModel;

	@observable
	private loadingState: 'loading' | 'error' | 'done' = 'loading';

	@observable
	private errors: Record<string, unknown>;

	@computed
	private get formEntity(): string {
		const { entity } = this.props;
		return entity;
	}

	@computed
	private get versionId(): string {
		const { id } = this.props;
		return id;
	}

	@action
	private updateFormSchema = (entity?: FormEntityModel, errors?: Record<string, unknown>) => {
		if (entity) {
			this.entity = entity;
			this.loadingState = 'done';
		} else {
			this.errors = errors || {};
			this.loadingState = 'error';
		}
	};

	@action
	private compareWithOrganisationCustomFormVersion = (entity: FormEntityData) => {
		if (!(entity instanceof FormEntity)) {
			throw new Error('Form error');
		}

		CustomFormVersionEntity.fetch<CustomFormVersionEntity>({
			args: [
				[{ path: 'organisationId', comparison: 'equal', value: entity.site.organisationId }],
			],
		}).then(async res => {
			if (res.length === 0) {
				throw new Error('Cannot find organisation form');
			}

			const customFormVersion = res[0];

			await entity.getAllVersions();
			await entity.getPublishedVersion();

			if (entity.formVersions.length > 0) {
				runInAction(() => {
					const latestFormVersion = entity.formVersions.filter(f => f.id === entity.publishedVersionId)[0];

					const newFormData = mergeCustomFormVersionAndSiteForm(
						JSON.parse(customFormVersion.formdata),
						latestFormVersion.formData,
					);

					latestFormVersion.formData = newFormData;

					if (entity.publishedVersion) {
						entity.publishedVersion.formData = newFormData;
					}

					this.updateFormSchema(entity);
				});
			} else {
				throw new Error('Form error! Please contact support.');
			}
		}).catch(e => {
			alertToast(e.toString(), 'error');
			store.routerHistory.goBack();
		});
	}

	public componentDidMount(): void {
		const modelKey = Object.keys(Models).find(x => x.toLowerCase() === this.formEntity.toLowerCase());
		if (modelKey) {
			Models[modelKey]
				.fetch<FormEntityModel>({ ids: [this.versionId] })
				.then((d: FormEntityModel[]) => {
					const form = d[0];
					if (form instanceof FormEntity) {
						this.compareWithOrganisationCustomFormVersion(form);
					} else {
						this.updateFormSchema(form);
					}
				})
				.catch((e: any) => {
					this.updateFormSchema(undefined, e);
				});
		} else {
			store.routerHistory.push('/404');
		}
	}

	private renderContents = () => {
		switch (this.loadingState) {
			case 'loading': return <Spinner />;
			case 'error': return `Something went wrong with loading the data. ${JSON.stringify(this.errors)}`;
			case 'done': return <FormEntityDesigner form={this.entity} />;
		}
	}

	public render() {
		return (
			<SecuredPage>
				<div className="body-content admin">
					{this.renderContents()}
				</div>
			</SecuredPage>
		);
	}
}
