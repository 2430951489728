import { useState, useEffect } from 'react';

const useDeviceDetect = () => {
	const checkForMobileDevice = () => {
		const windowWidth = window.innerWidth;
		return windowWidth <= 576;
	};

	const checkForTabletDevice = () => {
		const windowWidth = window.innerWidth;
		return windowWidth <= 768;
	};

	const [isMobile, setIsMobile] = useState(checkForMobileDevice());
	const [isTablet, setIsTablet] = useState(checkForTabletDevice());

	useEffect(() => {
		const handlePageResized = () => {
			setIsMobile(checkForMobileDevice);
			setIsTablet(checkForTabletDevice);
		};

		window.addEventListener('resize', handlePageResized);
		window.addEventListener('orientationchange', handlePageResized);
		window.addEventListener('load', handlePageResized);
		window.addEventListener('reload', handlePageResized);

		return () => {
			window.removeEventListener('resize', handlePageResized);
			window.removeEventListener('orientationchange', handlePageResized);
			window.removeEventListener('load', handlePageResized);
			window.removeEventListener('reload', handlePageResized);
		};
	}, []);

	return {
		isMobile,
		isTablet,
	};
};

export default useDeviceDetect;
