/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsSiteReferenceManyToMany from '../Security/Acl/VisitorsSiteReferenceManyToMany';
import AdministratorSiteReferenceManyToMany from '../Security/Acl/AdministratorSiteReferenceManyToMany';
import OrganisationAdminSiteReferenceManyToMany from '../Security/Acl/OrganisationAdminSiteReferenceManyToMany';
import SiteUserSiteReferenceManyToMany from '../Security/Acl/SiteUserSiteReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISiteUserSiteAttributes extends IModelAttributes {
	siteUserId: string;
	siteId: string;

	siteUser: Models.SiteUserEntity | Models.ISiteUserEntityAttributes;
	site: Models.SiteEntity | Models.ISiteEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('SiteUserSite')
export default class SiteUserSite
	extends Model
	implements ISiteUserSiteAttributes {
	public static acls: IAcl[] = [
		new VisitorsSiteReferenceManyToMany(),
		new AdministratorSiteReferenceManyToMany(),
		new OrganisationAdminSiteReferenceManyToMany(),
		new SiteUserSiteReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public siteUserId: string;

	@observable
	@attribute()
	public siteId: string;

	@observable
	@attribute({ isReference: true })
	public siteUser: Models.SiteUserEntity;

	@observable
	@attribute({ isReference: true })
	public site: Models.SiteEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ISiteUserSiteAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.siteUserId) {
				this.siteUserId = attributes.siteUserId;
			}
			if (attributes.siteId) {
				this.siteId = attributes.siteId;
			}

			if (attributes.siteUser) {
				if (attributes.siteUser instanceof Models.SiteUserEntity) {
					this.siteUser = attributes.siteUser;
					this.siteUserId = attributes.siteUser.id;
				} else {
					this.siteUser = new Models.SiteUserEntity(attributes.siteUser);
					this.siteUserId = this.siteUser.id;
				}
			} else if (attributes.siteUserId !== undefined) {
				this.siteUserId = attributes.siteUserId;
			}

			if (attributes.site) {
				if (attributes.site instanceof Models.SiteEntity) {
					this.site = attributes.site;
					this.siteId = attributes.site.id;
				} else {
					this.site = new Models.SiteEntity(attributes.site);
					this.siteId = this.site.id;
				}
			} else if (attributes.siteId !== undefined) {
				this.siteId = attributes.siteId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
