import * as React from 'react';
import classNames from 'classnames';
import InputWrapper, { LabelPositions, InputType } from '../Inputs/InputWrapper';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { DisplayType } from '../Models/Enums';
import { useEffect } from 'react';

export interface IThreeOptionsToggleableButtonProps<T> {
	id: string;
	model: T;
	modelProperty: string;
	className?: string;
	name: string;
	displayType?: DisplayType;
	label?: React.ReactNode;
	tooltip?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	innerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
	errors?: string | string[];
	/** Action to perform after the onChange method is called */
	onAfterChange?: (value: string) => void;
	/**
	 * Should the key for the fields be random uuids. This is useful for when there is a chance for non unique values.
	 * If this is not set or is false then the option field is used as the key.
	 */
	uuidKey?: boolean;
}

export const ThreeOptionsToggleableButton = observer(<T, >(props: IThreeOptionsToggleableButtonProps<T>) => {
	const {
		displayType,
		tooltip,
		isDisabled,
		isReadOnly,
		isRequired,
		errors,
		label,
		modelProperty,
		id,
		innerProps,
		model,
		uuidKey,
		name,
		className,
	} = props;

	const classes = classNames(
		className,
		'input-group-wrapper__3-options',
	);

	const tooltipId = `${id}-tooltip`;
	const labelNode = label
		? (
			<p
				className="input-group__3-options-header txt-bold"
				aria-describedby={tooltip ? tooltipId : undefined}
			>
				{label}
			</p>
		)
		: null;
	const groupName = name;

	if (innerProps) {
		innerProps['aira-live'] = 'assertive';
	}

	const onChecked = action((event: React.ChangeEvent<HTMLInputElement>) => {
		props.model[props.modelProperty] = event.target.value;
		props.onAfterChange?.(event.target.value);
	});

	const options: {
		id: string;
		value: string;
		className: string;
	}[] = [
		{
			id: 'NOT-GOOD',
			value: 'NOT GOOD',
			className: 'bad',
		},
		{
			id: 'UNSURE',
			value: 'UNSURE',
			className: 'neutral',
		},
		{
			id: 'GOOD',
			value: 'GOOD',
			className: 'good',
		},
	];

	useEffect(() => {
		if (!model[modelProperty]) {
			runInAction(() => {
				model[modelProperty] = 'UNSURE';
			});
		}
	}, [model, modelProperty]);

	return (
		<InputWrapper
			isInputGroup
			wrapperId={id}
			errors={errors}
			isRequired={isRequired}
			id={id}
			className={classes}
			displayType={displayType}
			innerProps={innerProps}
		>
			{labelNode}
			{options.map(option => (
				<InputWrapper
					className={option.className}
					inputType={InputType.RADIO}
					key={id + option.id}
					label={{ text: option.value, position: LabelPositions.AFTER }}
					inputId={id + option.id}
				>
					<input
						type="radio"
						name={groupName}
						id={id + option.id}
						value={option.value}
						checked={model[modelProperty] === option.value}
						key={id + option.id}
						onChange={onChecked}
						disabled={isDisabled || isReadOnly}
					/>
				</InputWrapper>
			))}
		</InputWrapper>
	);
});
