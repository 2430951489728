import { IStore } from '../Models/Store';
import { Form, Slide } from './Schema/Question';
import { runInAction } from 'mobx';

export const mergeBaseFormAndCustomFormVersionFormData = (baseForm: Form, customFormVersion: Form): Form => {
	runInAction(() => {
		baseForm.slides.forEach(baseFormSlide => {
			const customFormVersionSlide = customFormVersion.slides.find(s => s.id === baseFormSlide.id);

			if (customFormVersionSlide) {
				baseFormSlide.disabled = customFormVersionSlide.disabled;
			}
		});
	});

	return baseForm;
};

export const mergeCustomFormVersionAndSiteForm = (customFormVersion: Form, siteForm: Form): Form => {
	const customSlides = siteForm.slides.filter(s => !s.isBaseFormSlide);

	if (customSlides.length > 0) {
		customFormVersion.slides = [...customFormVersion.slides, ...customSlides];
	}

	return customFormVersion;
};

export const isEditingBaseFormEntity = () => {
	return window.location.href.includes('BaseFormEntity');
};

export const isEditingOrganisationFormEntity = () => {
	return window.location.href.includes('Organisation');
};

export const isEditingSiteFormEntity = () => {
	return window.location.href.includes('FormEntity') && !isEditingBaseFormEntity();
};

/**
 * Determine whether a component should be display on form based on user types and form type
 *
 * Non-base form slides and super admins has full control
 *
 * @param store - Shared root store
 * @param allowedOnBaseForm - whether a component can be displayed when editing BaseFormEntity
 * @param allowedOnCustomFormVersion - whether a component can be displayed when editing CustomFormVersionEntity
 * @param allowedOnSiteForm - whether a component can be displayed when editing site FormEntity
 * @param allowedUserGroups - the group of users allowed to view the component
 * @param slide - the slide that contains the component
 */
export const canFormComponentRender = (
	store: IStore,
	allowedOnBaseForm: boolean = false,
	allowedOnCustomFormVersion: boolean = false,
	allowedOnSiteForm: boolean = false,
	allowedUserGroups: string[] = [],
	slide?: Slide,
) => {
	if (
		(slide && !slide.isBaseFormSlide)
		|| store.isSuperAdmin
	) {
		return true;
	}

	if (
		(!allowedOnBaseForm && isEditingBaseFormEntity())
		|| (!allowedOnCustomFormVersion && isEditingOrganisationFormEntity())
		|| (!allowedOnSiteForm && isEditingSiteFormEntity())
	) {
		return false;
	}

	const userGroup = store.userGroups;

	return userGroup.some(g => allowedUserGroups.indexOf(g.name) >= 0);
};
