/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { Model } from 'Models/Model';
import { IAttributeProps } from './IAttributeProps';
import { FormTile } from 'Forms/FormTile';
import { observer } from 'mobx-react';
import { SubmissionEntityData } from 'Forms/FormEntityData';
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

// % protected region % [Add any additional imports here] off begin
// % protected region % [Add any additional imports here] end

interface IAttributeFormDataProps<T extends Model> extends IAttributeProps<T> {
	onChangeAndBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
export default class AttributeFormData<T extends Model & SubmissionEntityData>
	extends React.Component<IAttributeFormDataProps<T>> {
	public render() {
		const {
			model, options, className, isReadonly,
		} = this.props;
		// % protected region % [Modify the tile to be returned here] off begin
		if (model.formVersion && model.formVersion.formData) {
			return (
				<FormTile
					className={className}
					model={model[options.attributeName]}
					schema={model.formVersion.formData}
					isReadOnly={isReadonly}
					disableShowConditions={isReadonly}
					preview
				/>
			);
		}

		return <></>;

		// % protected region % [Modify the tile to be returned here] end
	}
}
