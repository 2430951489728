/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { Component, ReactNode } from 'react';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';
import classNames from 'classnames';
import { Button } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { Form, Question } from './Schema/Question';
import { SlideTile } from './SlideTile';
import { buildValidationErrorMessage } from './Validators/ValidationUtils';
import CheckDisplayConditions from './Conditions/ConditionUtils';
import { getNestedQuestions } from './Questions/QuestionUtils';
import { Switch, Route, Redirect } from 'react-router';
import { store } from 'Models/Store';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFormProps {
	isReadOnly?: boolean;
	className?: string;
	submitText?: string;
	disableShowConditions? : boolean;
	// % protected region % [Add extra form tile props here] off begin
	// % protected region % [Add extra form tile props here] end
}

export interface IFormTileProps<T> extends IFormProps {
	schema: Form;
	model: T;
	onSubmit?: (model: T) => void;
	preview?: boolean;
	routePath?: string;
	// % protected region % [Add to IFormTileProps here] off begin
	// % protected region % [Add to IFormTileProps here] end
}

@observer
export class FormTile<T> extends Component<IFormTileProps<T>> {
	@observable
	private reValidate: boolean = false;

	static defaultProps: Partial<IFormProps> = {
		submitText: 'Submit',
		// % protected region % [Add to defaultProps here] off begin
		// % protected region % [Add to defaultProps here] end
	};

	// % protected region % [Add extra class fields here] off begin
	// % protected region % [Add extra class fields here] end

	private onSubmit = (): void => {
		// % protected region % [Override onSubmit here] off begin
		const { onSubmit, model } = this.props;

		if (onSubmit) {
			if (this.validateForm()) {
				onSubmit(model);
			} else {
				runInAction((): void => {
					this.reValidate = true;
				});
			}
		}
		// % protected region % [Override onSubmit here] end
	};

	private isQuestionShown = (question: Question): boolean => {
		// % protected region % [Override isQuestionShown here] off begin
		const { showConditions } = question;
		const { disableShowConditions, model, schema } = this.props;

		if (showConditions !== undefined && !disableShowConditions) {
			return showConditions.every(condition => { return CheckDisplayConditions(condition, model, schema); });
		}

		return true;
		// % protected region % [Override isQuestionShown here] end
	};

	private validateQuestion = (question: Question): boolean => {
		// % protected region % [Override validateQuestion here] off begin
		const { model, schema } = this.props;
		const { validators } = question;
		let errorMessage: string[] = [];

		if (this.isQuestionShown(question)) {
			if (question.validators !== undefined) {
				errorMessage = buildValidationErrorMessage(validators, model, schema, false);
				return _.filter(errorMessage, e => e).length === 0;
			}
		}

		return true;
		// % protected region % [Override validateQuestion here] end
	};

	private validateQuestions = (questions: Question[]): boolean => {
		// % protected region % [Override validateQuestions here] off begin
		let valid: boolean = true;

		questions.forEach(q => {
			valid = valid && this.validateQuestion(q);
		});

		return valid;
		// % protected region % [Override validateQuestions here] end
	};

	private validateForm = (): boolean => {
		// % protected region % [Override validateForm here] off begin
		const { schema } = this.props;
		const questions = _.flatMap(schema.slides, o => getNestedQuestions(o.contents));
		return this.validateQuestions(questions);
		// % protected region % [Override validateForm here] end
	};

	private validateSlide = (): boolean => {
		// % protected region % [Override validateSlide here] off begin
		const { schema } = this.props;
		const questions = _.flatMap(getNestedQuestions(schema.slides[this.currentSlideNumber - 1].contents));
		return this.validateQuestions(questions);
		// % protected region % [Override validateSlide here] end
	};

	@observable
	private currentSlideNumber: number = 1;

	@action
	private nextSlide = () => {
		if (this.validateSlide()) {
			this.currentSlideNumber++;
		} else {
			this.reValidate = true;
		}
	}

	@action
	private nextSlideUrl = (slide: number) => {
		const questions = _.flatMap(getNestedQuestions(this.props.schema.slides[slide].contents));
		if (this.validateQuestions(questions)) {
			store.routerHistory?.push(`./${slide + 2}`);
		} else {
			this.reValidate = true;
		}
	}

	@action
	private previousSlide = () => {
		this.currentSlideNumber--;
	}

	private renderScrollForm(slideTiles: ReactNode[]): ReactNode {
		const {
			submitText, onSubmit,
		} = this.props;

		return (
			<>
				{slideTiles}
				<If condition={onSubmit !== undefined}>
					<Button onClick={this.onSubmit}>{submitText}</Button>
				</If>
			</>
		);
	}

	private renderPageForm(slideTiles: ReactNode[]): ReactNode {
		const {
			submitText, onSubmit, preview,
		} = this.props;
		const pages = slideTiles.length;
		return (
			<>

				<If condition={preview}>
					<h5>This is a preview using page based pagination.</h5>
				</If>
				{slideTiles[this.currentSlideNumber - 1]}
				<Button onClick={this.previousSlide} disabled={this.currentSlideNumber === 1}>Previous Slide</Button>
				<Button onClick={this.nextSlide} disabled={this.currentSlideNumber === pages}>Next Slide</Button>
				<If condition={onSubmit !== undefined && this.currentSlideNumber === pages}>
					<Button onClick={this.onSubmit}>{submitText}</Button>
				</If>
				<h3>{this.currentSlideNumber}/{pages}</h3>
			</>
		);
	}

	private renderUrlForm(slideTiles: ReactNode[]): ReactNode {
		const {
			onSubmit, routePath,
		} = this.props;
		const pages = slideTiles.length;
		let pageNumber = 1;
		const history = store.routerHistory;
		return (
			<>
				<section className="content">
					<Switch>
						{slideTiles.map((slide, i) => {
							const hasPrevPage = i > 0;
							const hasNextPage = i < (pages - 1);
							return (
								<Route key={pageNumber++} path={`${routePath}/slide/${i + 1}/`}>
									{slide}

									<div className="slide-button__container">

										<If condition={hasPrevPage}>
											<Button
												disabled={!hasPrevPage}
												onClick={() => history?.push(`./${i}`)}
											>
												Previous Slide
											</Button>
										</If>
										<If condition={hasNextPage}>
											<Button
												disabled={!hasNextPage}
												onClick={() => {
													this.nextSlideUrl(i);
												}}
											>
												Next Slide
											</Button>
										</If>
										<If condition={!hasNextPage}>
											<If condition={onSubmit !== undefined}>
												<Button
													onClick={this.onSubmit}
												>
													Submit
												</Button>
											</If>
										</If>
									</div>
								</Route>
							);
						})}
						<Redirect to={`${routePath}/slide/1`} />
					</Switch>
				</section>
			</>
		);
	}

	private renderForm(formType: string, slideTiles: ReactNode[]): ReactNode {
		const { preview } = this.props;

		if (preview && formType === 'url') {
			return this.renderPageForm(slideTiles);
		}
		switch (formType) {
			case 'page':
				return this.renderPageForm(slideTiles);
			case 'url':
				return this.renderUrlForm(slideTiles);
			case 'scroll':
				return this.renderScrollForm(slideTiles);
			default:
				return this.renderPageForm(slideTiles);
		}
	}

	public render(): ReactNode {
		// % protected region % [Customize render here] on begin
		const {
			className, schema, isReadOnly, disableShowConditions, model,
		} = this.props;

		const slideTiles = schema.slides.filter(s => !s.disabled).map((slide, i): ReactNode => {
			const key = `${slide.name}-${i}`;
			return (
				<SlideTile
					key={key}
					model={model}
					schema={schema}
					isReadOnly={isReadOnly}
					disableShowConditions={disableShowConditions}
					contents={slide.contents}
					name={slide.name}
					reValidate={this.reValidate}
					isBaseFormSlide={slide.isBaseFormSlide}
				/>
			);
		});

		return (
			<div className={classNames('forms-tile', className)}>
				{this.renderForm(schema.pagination.type, slideTiles)}
			</div>
		);
		// % protected region % [Customize render here] end
	}

	// % protected region % [Add any extra form tile methods here] off begin
	// % protected region % [Add any extra form tile methods here] end
}
