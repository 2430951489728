import React from 'react';
import CFEPLogo from '../../../Assets/Form/Images/CFEP_RGB_Transparent.png';

function SlideHeader(props: {siteName: string}) {
	const { siteName } = props;
	return (
		<div className="header slide-header slide-header-start">
			<p>{ siteName }</p>
			<img src={CFEPLogo} alt="CFEP Logo" height="75px" />
		</div>
	);
}

export default SlideHeader;
