import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { QuestionType, ValidatorType } from 'Forms/Schema/Question';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import TileOptions from '../Options/TileOptions';
import { hasRequiredValidator } from '../../Validators/ValidationUtils';
import CompareBoolean from 'Forms/Conditions/CompareBoolean';
import ValidateBoolean from 'Forms/Validators/ValidateBoolean';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
import PillQuestionOptions from '../Options/PillQuestionOptions';
import classNames from 'classnames';
import { DisplayType } from '../../../Views/Components/Models/Enums';
import { action } from 'mobx';

@observer
export class PillQuestionTileOptions extends Component<QuestionTileOptionsProps> {
	public render() {
		const { question, schema } = this.props;

		return (
			<>
				<TileOptions
					question={question}
					schema={schema}
					hasShowConditions={false}
					hasValidators
					hasTooltip
					tooltipInputLabel="Pill Selectable Question description"
				/>
				<PillQuestionOptions
					schema={schema}
					question={question}
				/>
			</>
		);
	}
}

interface PillQuestionTileQuestionOptions {
	values?: {
		id: string;
		value: string;
	}[]
}

export interface IPillQuestionTileProps<T> extends QuestionComponentProps<T> {
	options: PillQuestionTileQuestionOptions;
}

@observer
export class PillQuestionTile<T> extends QuestionComponent<T, IPillQuestionTileProps<T>> implements IQuestionTile {
	static displayName = 'Pill Selectable Question';

	static questionType: QuestionType = 'checkbox-pill';

	static optionsMenu = PillQuestionTileOptions;

	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
		{ display: 'Not equal', value: 'notEqual' },
	];

	static validatorOptions: { display: string, value: ValidatorType }[] = [
		{ display: 'Required', value: 'required' },
	];

	static stylingOptions = undefined;

	static compareFunction = CompareBoolean;

	static validateFunction = ValidateBoolean;

	@action
	componentDidMount() {
		const { model, options, id } = this.props;

		if (options.values) {
			if (options.values.length <= 0) {
				options.values.push({
					value: 'Option 1',
					id: `${id}-1`,
				});
			}

			for (const option of options.values) {
				const value = model[option.id];

				if (!value) {
					model[option.id] = false;
				}
			}
		}
	}

	public render() {
		const {
			title, model, id, isReadOnly, validators, toolTip, className, checkValidation, options,
		} = this.props;

		const classes = classNames(
			'input-group-wrapper__checkbox',
			'input-group-wrapper__pill',
			className,
			`input-group-${DisplayType.BLOCK}`,
		);

		return (
			<div
				id={id}
				className={classes}
				aria-live="assertive"
			>
				{title ? (
					<h4 className="input-group__checkbox-header">
						{title}
					</h4>
				) : null}
				{title && toolTip ? <p>{toolTip}</p> : ''}

				<div className="pill-checkbox-input-group">
					{options && options.values ? options.values.map(value => (
						<Checkbox
							id={value.id}
							key={value.id}
							name={value.id}
							model={model}
							modelProperty={value.id}
							label={value.value}
							labelVisible
							isReadOnly={isReadOnly}
							isRequired={hasRequiredValidator(validators)}
							onAfterChecked={checkValidation}
							className="pill-checkbox"
						/>
					)) : null}
				</div>
			</div>
		);
	}
}
