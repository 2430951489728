/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import {
	action, observable, computed, autorun, runInAction,
} from 'mobx';
import _, { findIndex } from 'lodash';
import { store } from 'Models/Store';
import { QueryOptions } from 'Models/Model';
import { FormVersion } from 'Forms/FormVersion';
import Question from 'Forms/Schema/Question';
import gql from 'graphql-tag';
import moment from 'moment';

import alertToast from 'Util/ToastifyUtils';

import { ChartType, QuestionTypeMap, determineScaleType } from 'Views/Components/Charts/ChartTypes';

import SlideMenu, { StaticSlides, ItemFilter, MenuSlidesOrdered } from 'Views/Pages/Dashboard/SlideMenu';
import DatePickerMenu from 'Views/Pages/Dashboard/DatePickerMenu';
import * as CSVExport from 'Views/Pages/Dashboard/CSVExport';

import Spinner from 'Views/Components/Spinner/Spinner';
import {
	Button, Display, Colors,
} from 'Views/Components/Button/Button';

const NPSChart = React.lazy(() => import('Views/Components/Charts/NPSChart'));
const RatingChart = React.lazy(() => import('Views/Components/Charts/RatingChart'));
const CountChart = React.lazy(() => import('Views/Components/Charts/CountChart'));
const NPSLineChart = React.lazy(() => import('Views/Components/Charts/LineChart/NPSLineChart'));
const RatingLineChart = React.lazy(() => import('Views/Components/Charts/LineChart/RatingLineChart'));
const ColumnChart = React.lazy(() => import('Views/Components/Charts/ColumnChart'));

const RadioButtonHighlightChart = React
	.lazy(() => import('Views/Components/Charts/MetricHighlightChart/RadioButtonHighlightChart'));
const RadioBarChart = React.lazy(() => import('Views/Components/Charts/RadioBarChart'));
const StackedRadioBarChart = React.lazy(() => import('Views/Components/Charts/StackedRadioBarChart'));
const MultiRadioAggregate = React
	.lazy(() => import('Views/Components/Charts/MetricHighlightChart/MultiRadioAggregate'));
const StackedRadioAggregate = React
	.lazy(() => import('Views/Components/Charts/MetricHighlightChart/StackedRadioAggregate'));
const CommentListChart = React
	.lazy(() => import('Views/Components/Charts/CommentListChart'));
// % protected region % [Add any extra imports here] end

export interface DashboardPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class DashboardPage extends React.Component<DashboardPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	@observable
	currentSlideId: string = StaticSlides.NPS;

	@observable
	siteName = '';

	@observable
	chartCsvGenerators: (CSVExport.CSVDataMapper)[] = [];

	@action
	upsertMapper = (mapper: CSVExport.CSVDataMapper) => {
		const existingIndex = this.chartCsvGenerators.findIndex(x => x.id === mapper.id);
		if (existingIndex === -1) {
			this.chartCsvGenerators.push(mapper);
		} else {
			this.chartCsvGenerators[existingIndex] = mapper;
		}
	}

	@action
	generatorCleanup = (id: string) => {
		this.chartCsvGenerators = this.chartCsvGenerators.filter(x => x.id !== id);
	}

	@observable
	range: Date[] = [
		(moment().subtract(7, 'days').startOf('day')).toDate(),
		(moment().endOf('day')).toDate(),
	];

	@observable
	private combinedSchema: Question.Form;

	@computed
	private get menuConfig(): ItemFilter[] {
		const cfg = new Array(this.combinedSchema.slides.length).fill('foo')
			.map((x, i) => (
				{
					disabled: this.combinedSchema?.slides[i]
						? this.combinedSchema?.slides[i]?.disabled
						: true,
				}
			));
		return cfg;
	}

	private isSlidePresent(slideId: StaticSlides): boolean {
		const slide = this.combinedSchema?.slides.find(x => x.id === slideId);
		return !slide?.disabled || !!slide;
	}

	private isCurrentSlide(slide: StaticSlides): boolean {
		return slide === this.currentSlideId;
	}

	private generateCSV() {
		const files = this.chartCsvGenerators
			.reduce((acc, curr) => {
				return [...acc, ...curr.generate()];
			}, [] as { blob: Blob; path: string; }[]);

		CSVExport.downloadZip(files, `Dashboard ${this.currentSlideMenuLabel}`);
	}

	@computed
	private get currentSlideMenuLabel(): string {
		return MenuSlidesOrdered.find(x => x.id === this.currentSlideId)?.label || '';
	}

	componentDidMount(): void {
		if (store.isSiteUser && !store.isOrganisationAdmin && !store.isCfepAdmin && !store.isSuperAdmin
			&& !this.sharedConfig.siteId) {
			alertToast(
				// eslint-disable-next-line max-len
				"Page URL is incorrect - Site identifier is missing. Try clicking on 'Home' or 'Dashboard' in the menu to navigate to this page correctly.",
				'error',
			);
		}
		autorun(
			() => {
				this.getSchema();
			},
		);
	}

	@computed
	get sharedConfig() {
		// @ts-ignore
		const { match: { params: { siteId } } } = this.props;
		return {
			organisationId: store.userOrganisationId,
			siteId,
			startDate: this.range[0],
			endDate: this.range[1],
		};
	}

	private async getSchema() {
		/**
		 * We need to retrieve the Form Schemas for the given period of time and resolve them into a single schema.
		 * We can then use the resolved schema to determine which Form Slides to make visible in the Dashboard
		 *
		 * To do this, we retrieve all schemas within the specified timeframe, and the latest schema before it (for instances where there are no schema changes within the timeframe)
		 */
		const { organisationId, siteId } = this.sharedConfig;

		const formVersions: FormVersion[] = [];
		let publishedVersionId: null | string = null;

		if (!siteId && !organisationId) {
			alertToast('Missing Organisation and Site identifiers - is the current page URL correct?', 'error');
			return;
		}

		if (!siteId) {
			const byOrgQuery: QueryOptions = {
				query: gql`
				query getFormVersionsForOrg(
					$organisationIds: [String], $startDate: [String], $endDate: [String]
				) {
					timeframe: formEntityFormVersions(
						where: [
							{path: "form.site.organisationId", comparison: in, value: $organisationIds}
							{path: "created", comparison: greaterThanOrEqual, value: $startDate}
							{path: "created", comparison: lessThanOrEqual, value: $endDate}
						]
						orderBy: [
							{path: "created", descending: true }
						]
					) {
						id
						created
						modified
						formData
						form {
							publishedVersionId
						}
					}
					latestPrior: formEntityFormVersions(
						where: [
							{path: "form.site.organisationId", comparison: in, value: $organisationIds}
							{path: "created", comparison: lessThan, value: $startDate}
						],
						orderBy: [
							{path: "created", descending: true }
						]
					) {
						id
						created
						modified
						formData
						form {
							publishedVersionId
						}
					}
				}
				`,
				fetchPolicy: 'network-only',
				variables: {
					organisationIds: [organisationId],
					startDate: this.range[0],
					endDate: this.range[1],
				},
			};

			await store.apolloClient
				.query(byOrgQuery)
				.then(async resp => {
					const versions: any = [
						...resp.data?.timeframe,
						...(resp.data?.latestPrior.length > 0 ? [resp.data?.latestPrior[0]] : []),
					];

					const mappedVersions = versions.map((x: any) => {
						publishedVersionId = x.form.publishedVersionId;
						return {
							id: x.id,
							created: x.created,
							modified: x.modified,
							version: x.version,
							formData: JSON.parse(x.formData),
						} as FormVersion;
					});

					formVersions.push(...mappedVersions);
				})
				.catch(e => {
					console.error(e);
				});
		}

		if (siteId) {
			const bySiteQuery: QueryOptions = {
				query: gql`
					query getFormVersionsForSite(
						$siteId: ID, $siteIds: [String], $startDate: [String], $endDate: [String],
					) {
						site: siteEntity(id: $siteId) {
							id
							name
						}
						timeframe: formEntityFormVersions(
							where: [
								{path: "form.siteId", comparison: in, value: $siteIds}
								{path: "created", comparison: greaterThanOrEqual, value: $startDate}
								{path: "created", comparison: lessThanOrEqual, value: $endDate}
							]
							orderBy: [
								{path: "created", descending: true}
							]
						) {
							id
							created
							modified
							formData
							form {
								publishedVersionId
							}
						}
						latestPrior: formEntityFormVersions(
							where: [
								{path: "form.siteId", comparison: in, value: $siteIds}
								{path: "created", comparison: lessThan, value: $startDate}
							]
							orderBy: [
								{path: "created", descending: true}
							]
						) {
							id
							created
							modified
							formData
							form {
								publishedVersionId
							}
						}
					}
				`,
				fetchPolicy: 'network-only',
				variables: {
					siteId,
					siteIds: [siteId],
					startDate: this.range[0],
					endDate: this.range[1],
				},
			};

			await store.apolloClient
				.query(bySiteQuery)
				.then(async resp => {
					const versions: any = [
						...resp.data?.timeframe,
						...(resp.data?.latestPrior.length > 0
							? [resp.data?.latestPrior[0]]
							: []),
					];
					runInAction(() => {
						this.siteName = resp.data?.site?.name;
					});

					const mappedVersions = versions.map((x: any) => {
						publishedVersionId = x.form.publishedVersionId;
						return {
							id: x.id,
							created: x.created,
							modified: x.modified,
							version: x.version,
							formData: JSON.parse(x.formData),
						} as FormVersion;
					});

					formVersions.push(...mappedVersions);
				})
				.catch(e => {
					console.error(e);
				});
		}

		// it's OK if there's no form versions - forms may just not be configured for this organisation yet
		if (formVersions.length >= 1) {
			const combinedSchema: Question.Form = {
				slides: [],
				pagination: {
					type: 'page',
				},
				customOptions: [],
			};

			const latestVersion = formVersions[0];

			combinedSchema.slides = latestVersion.formData.slides
				.reduce((acc: Question.Slide[], curr: Question.Slide, index) => {
					if (curr.disabled === true) {
						// if latest version slide is disabled, check to see if any other versions being displayed have it enabled
						const foundEnabledSlide = formVersions
							.find(version => {
								return version.formData.slides[index]
									? !version.formData.slides[index].disabled
									: false;
							});

						// if we found the same slide enabled in at least one of these forms, enable it
						if (foundEnabledSlide) {
							return [
								...acc,
								{
									...curr,
									disabled: false,
								},
							];
						}
					}
					return [
						...acc,
						curr,
					];
				}, []);

			runInAction(() => {
				this.combinedSchema = combinedSchema;

				const currentSlide = combinedSchema?.slides.find(x => x.id === this.currentSlideId);

				// if the slide we're currently on becomes missing or disabled, find the next available one and display it
				if (currentSlide?.disabled || !currentSlide) {
					const nextActive = combinedSchema.slides.find(x => !x.disabled);
					this.currentSlideId = nextActive?.id;
				}
			});
		// @ts-ignore
		// eslint-disable-next-line react/destructuring-assignment
		} else if (formVersions.length < 1) {
			alertToast(
				// eslint-disable-next-line max-len
				'No Form data was found - you may be filtering for a period of time that predates this site having a form', 'warning',
			);
		} else {
			alertToast('No Form data was found - is there a Form configured for this organisation?', 'error');
		}
	}

	@action
	onMenuItemClick = (id: string) => {
		// this.chartCsvGenerators = []; // clear csv generators
		this.currentSlideId = id;
	}

	@computed
	private get dynamicChartWidth() {
		let chartCount = 1;
		if ( // add 1 for each exclusive condition that would result in a first-row chart being sivielvisible
			this.getPresentChartQuestionType(ChartType.NPS)
			|| this.getPresentChartQuestionType(ChartType.Rating)
			|| this.isCurrentSlide(StaticSlides.Advice)
			|| this.isCurrentSlide(StaticSlides.Kindness)
			|| this.isCurrentSlide(StaticSlides.Feelings)
			|| this.getPresentChartQuestionType(ChartType.StackedRadioAggregate)
		) chartCount += 1;

		// add 1 for condition that would result in third chart being shown
		if (this.getPresentChartQuestionType(ChartType.CommentCount)) chartCount += 1;

		switch (chartCount) {
			case 0:
				return 'hidden';
			case 1:
				return 'full-width';
			case 2:
				return 'half-width';
			default:
				return 'third-width';
		}
	}

	// For a given question type, retrieve all instances of that question from the schema for the current slide
	getQuestions(questionType: Question.QuestionType | undefined): Question.Question[] {
		// search current slide for matching type
		if (!questionType || !this.combinedSchema) return [];
		const slide = this.combinedSchema.slides.find(x => x.id === this.currentSlideId);
		if (!slide) return [];

		const questions = slide?.contents
			.filter(slideQuestion => {
				return slideQuestion.questionType === questionType;
			});

		return questions;
	}

	// For a given question type, get the first instance of that question from the schema for the current slide
	getQuestion(questionType: Question.QuestionType | undefined): Question.Question | undefined {
		return this.getQuestions(questionType)?.[0];
	}

	getQuestionById(
		questionType: Question.QuestionType | undefined,
		questionId: string,
	): Question.Question | undefined {
		return this.getQuestions(questionType)?.find(x => x.id === questionId);
	}

	getPresentChartQuestionType(chartType: ChartType): Question.QuestionType | undefined {
		const questionType = QuestionTypeMap[chartType];

		if (!questionType) {
			throw new Error('Chart visibility being checked for Chart that does not depend on question type presence');
		}

		const question = this.getQuestion(questionType);

		// some chart types are only visible for specific sub-types of question types
		switch (question?.questionType) {
			case 'scale':
				if (!determineScaleType(question, chartType)) return undefined;
		}
		return question?.questionType;
	}

	getQuestionIds(questionType: Question.QuestionType | undefined): string[] {
		if (!questionType) return [];

		const questions = this.getQuestions(questionType);

		switch (questionType) {
			case 'checkbox-pill':
			case '3-options': {
				const nestedIds = questions
					.map(q => q.options?.values
						.map(({ id }: { id: string }) => id));
				return _.flatMap(nestedIds, x => x);
			}
			default:
				return questions.map(x => x.id);
		}
	}

	getRadioBarChartTitle(questionType: Question.QuestionType | undefined): string {
		if (!questionType) return '';
		const questions = this.getQuestions(questionType);

		// common case - just use the question title
		if (questions.length === 1) return questions[0].title;

		// infrequent case - if multiple questions, assume there is a statement on the page to use instead
		// (this is for charts that combine multiple questions into a single chart in the dashboard)
		const slide = this.combinedSchema.slides.find(x => x.id === this.currentSlideId);
		return slide?.contents.find(x => x.questionType === 'statement')?.title || '';
	}

	/**
	 * Compute Chart properties sensitive to causing render loops
	 */
	@computed
	private get getColumnChartProps() {
		const questionIds = this.getQuestionIds(
			this.getPresentChartQuestionType(
				ChartType.MultiCheckboxColumn,
			),
		);

		const questionLabels = this.getQuestion(
			this.getPresentChartQuestionType(ChartType.MultiCheckboxColumn),
		)?.options?.values.map((x: any) => x.value);

		return { questionIds, questionLabels };
	}

	@computed
	private get getRadioBarChartProps() {
		const questionIds = this.getQuestionIds(
			this.getPresentChartQuestionType(ChartType.RadioButtonBar),
		);
		const questions = this.getQuestions(
			this.getPresentChartQuestionType(ChartType.RadioButtonBar),
		);

		// the radio bar chart can exist either with multiple charts inside it (i.e within a single dashboard element)
		// -or-
		// multiple instances of a radio bar chart on the page.
		// Because of this, it always takes multiple questions ids and/or questions.
		// to prevent inifnite render loops when the child component adds it's CSV generation callback to this component,
		// we need to compute the question array prop so the array reference doesn't change every DashboardPage render.
		// Yeah, it's a little confusing, but it works.
		const nestedQuestions = questions.map(q => ({
			questions: [q],
			questionIds: [q.id],
		}));

		return { questionIds, questions, nestedQuestions };
	}
	// % protected region % [Add class properties here] end

	render() {
		const {
			match,
			location,
			history,
			staticContext,
			// % protected region % [Destructure any additional props here] off begin
			// % protected region % [Destructure any additional props here] end
		} = this.props;

		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		// eslint-disable-next-line prefer-const
		let contents = (
			// eslint-disable-next-line max-len
			<SecuredPage groups={['Super Administrators', 'Administrator', 'OrganisationAdmin', 'SiteUser']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					<h1>
						Dashboard
					</h1>
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		contents = (
			// eslint-disable-next-line max-len
			<SecuredPage groups={['OrganisationAdmin', 'SiteUser']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				<div className="body-content dashboard-background">
					<div className="dashboard-page">
						<div>
							<div className="header-container">
								<h1>
									{this.sharedConfig.siteId ? `${this.siteName} ` : 'Organisation '}Dashboard
								</h1>
								<Button
									className="export-csv-btn"
									display={Display.Solid}
									colors={Colors.Primary}
									disabled={!this.combinedSchema}
									onClick={() => this.generateCSV()}
								>
									export {this.currentSlideMenuLabel} data to csv
								</Button>
							</div>
							{
								this.combinedSchema
									? (
										<>
											<SlideMenu
												defaultSlide={this.currentSlideId}
												onItemClick={this.onMenuItemClick}
												itemFilter={this.menuConfig}
												allSlides={this.combinedSchema.slides}
											/>
											<div className="dashboard-slide-header">
												<h3>{this.currentSlideMenuLabel} Dashboard</h3>
												<DatePickerMenu
													range={this.range}
													onUpdate={action(newRange => {
														this.range = [
															moment(newRange[0]).toDate(),
															moment(newRange[1]).endOf('day').toDate(),
														];
													})}
												/>
											</div>
										</>
									)
									: null
							}
						</div>
						{this.combinedSchema
							? (
								<React.Suspense fallback={<Spinner />}>
									<div className="chart-container">
										{/* FIRST ROW */}
										{this.getPresentChartQuestionType(ChartType.NPS)
											? (
												<NPSChart
													{...this.sharedConfig}
													questionIds={this.getQuestionIds(
														this.getPresentChartQuestionType(ChartType.NPS),
													)}
													className={this.dynamicChartWidth}
												/>
											) : null}

										{this.getPresentChartQuestionType(ChartType.Rating)
											? (
												<RatingChart
													{...this.sharedConfig}
													title={this.currentSlideMenuLabel}
													questionIds={this.getQuestionIds(
														this.getPresentChartQuestionType(ChartType.Rating),
													)}
													className={this.dynamicChartWidth}
												/>
											) : null}

										{/* ONLY ON 4th or 5th SLIDE */}
										{this.isCurrentSlide(StaticSlides.Advice)
											|| this.isCurrentSlide(StaticSlides.Kindness)
											? (
												<RadioButtonHighlightChart
													{...this.sharedConfig}
													questionIds={this.getQuestionIds(
														this.getPresentChartQuestionType(ChartType.RadioButtonCount),
													)}
													className={this.dynamicChartWidth}
												/>
											) : null}

										{this.getPresentChartQuestionType(ChartType.CommentCount)
											? (
												<CountChart
													{...this.sharedConfig}
													type={ChartType.CommentCount}
													questionIds={this.getQuestionIds(
														this.getPresentChartQuestionType(ChartType.CommentCount),
													)}
													className={this.dynamicChartWidth}
												/>
											) : null}

										{this.isCurrentSlide(StaticSlides.Feelings)
											? (
												<MultiRadioAggregate
													{...this.sharedConfig}
													questionIds={this.getQuestionIds(
														this.getPresentChartQuestionType(ChartType.MultiRadioAggregate),
													)}
													className={this.dynamicChartWidth}
												/>
											) : null}

										{this.getPresentChartQuestionType(ChartType.StackedRadioAggregate)
											? (
												<StackedRadioAggregate
													{...this.sharedConfig}
													questionIds={this.getQuestionIds(
														// eslint-disable-next-line max-len
														this.getPresentChartQuestionType(ChartType.StackedRadioAggregate),
													)}
													className={this.dynamicChartWidth}
												/>
											) : null}

										<CountChart // this chart is always visible
											{...this.sharedConfig}
											type={ChartType.SubmissionCount}
											questionIds={[]} // chart always shown does not target specific questions
											className={this.dynamicChartWidth}
										/>

										{/* SECOND AND FURTHERS ROWS */}

										{this.getPresentChartQuestionType(ChartType.NPSLine)
											? (
												<NPSLineChart
													{...this.sharedConfig}
													questionId={this.getQuestionIds(
														this.getPresentChartQuestionType(ChartType.NPSLine),
													)[0]}
													showFilters={this.isSlidePresent(StaticSlides.Demographics)}
													passMapperToParent={this.upsertMapper}
													removeMapperFromParent={this.generatorCleanup}
												/>
											) : null}

										{this.getPresentChartQuestionType(ChartType.RatingLine)
											? (
												<RatingLineChart
													{...this.sharedConfig}
													questionId={this.getQuestionIds(
														this.getPresentChartQuestionType(ChartType.RatingLine),
													)[0]}
													currentPageLabel={this.currentSlideMenuLabel}
													showFilters={this.isSlidePresent(StaticSlides.Demographics)}
													passMapperToParent={this.upsertMapper}
													removeMapperFromParent={this.generatorCleanup}
												/>
											) : null}

										{/* display multiple questions inside radiobarchart - INFREQUENT CASE */}
										{this.getPresentChartQuestionType(ChartType.RadioButtonBar)
											&& this.isCurrentSlide(StaticSlides.Feelings)
											? (
												<RadioBarChart
													{...this.sharedConfig}
													{...this.getRadioBarChartProps}
													title={this.getRadioBarChartTitle(
														this.getPresentChartQuestionType(ChartType.RadioButtonBar),
													)}
													passMapperToParent={this.upsertMapper}
													removeMapperFromParent={this.generatorCleanup}
												/>
											)
											: null}
										{/* display single question inside multiple radiobarchart - TYPICAL CASE */}
										{this.getPresentChartQuestionType(ChartType.RadioButtonBar)
											&& !this.isCurrentSlide(StaticSlides.Feelings) // if you have no feelings ;_;
											? this.getRadioBarChartProps.nestedQuestions.map((q, i) => {
												// we normally need to refer to using this.getQuestionIds to ensure enumerated questions behave correctly
												// but since this is just for a single controlled case where radio button submission data is not, we use getQuestions instead
												return (
													<RadioBarChart
														{...this.sharedConfig}
														{...this.getRadioBarChartProps}
														questionIds={q.questionIds}
														questions={q.questions}
														title={q.questions[0].title}
														key={q.questions[0].id}
														passMapperToParent={this.upsertMapper}
														removeMapperFromParent={this.generatorCleanup}
													/>
												);
											})
											: null}

										{this.getPresentChartQuestionType(ChartType.StackedBar)
											? (
												<StackedRadioBarChart
													{...this.sharedConfig}
													questionIds={this.getQuestionIds(
														this.getPresentChartQuestionType(ChartType.StackedBar),
													)}
													question={this.getQuestion(
														this.getPresentChartQuestionType(ChartType.StackedBar),
													)}
													title={this.getRadioBarChartTitle(
														this.getPresentChartQuestionType(ChartType.StackedBar),
													)}
													passMapperToParent={this.upsertMapper}
													removeMapperFromParent={this.generatorCleanup}
												/>
											) : null}

										{this.getPresentChartQuestionType(ChartType.MultiCheckboxColumn)
											? (
												<ColumnChart
													{...this.sharedConfig}
													{...this.getColumnChartProps}
													title={this.getQuestion(
														this.getPresentChartQuestionType(ChartType.MultiCheckboxColumn),
													)?.title || ''}
													passMapperToParent={this.upsertMapper}
													removeMapperFromParent={this.generatorCleanup}
												/>
											) : null}

										{this.getPresentChartQuestionType(ChartType.CommentList)
											? (
												this.getQuestionIds(
													this.getPresentChartQuestionType(ChartType.CommentList),
												).map(questionId => (
													<CommentListChart
														{...this.sharedConfig}
														key={questionId}
														questionId={questionId}
														title={this.getQuestionById(
															this.getPresentChartQuestionType(ChartType.CommentList),
															questionId,
														)?.title || ''}
														passMapperToParent={this.upsertMapper}
														removeMapperFromParent={this.generatorCleanup}
													/>
												))
											) : null}
									</div>
								</React.Suspense>
							)
							: <Spinner />}
					</div>
				</div>
			</SecuredPage>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default DashboardPage;
// % protected region % [Override export here] end
