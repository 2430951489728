/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { Component, ReactNode } from 'react';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { getNestedQuestions, questions } from 'Forms/Questions/QuestionUtils';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { DateTimePicker } from 'Views/Components/DateTimePicker/DateTimePicker';
import {
	Contents, Form, Question, Validator,
} from 'Forms/Schema/Question';
import { getAdditionalAttributeElement } from 'Forms/Validators/ValidationUtils';
// % protected region % [Add any further imports here] on begin
import { customQuestions } from 'Forms/Questions/QuestionUtils';
// % protected region % [Add any further imports here] end

interface ComboBoxOptions {
	display: string;
	value: string;
	// % protected region % [Add to ComboBoxOptions here] off begin
	// % protected region % [Add to ComboBoxOptions here] end
}

export interface TileOptionsProps {
	schema: Form;
	question: Question;
	hasShowConditions?: boolean;
	hasValidators?: boolean;
	hasTooltip?: boolean;
	children?: React.ReactNode;
	// % protected region % [Add to TileOptionsProps here] on begin
	tooltipInputLabel?: string;
	optionsChildren?: React.ReactNode;
	// % protected region % [Add to TileOptionsProps here] end
}

@observer
export default class TileOptions extends Component<TileOptionsProps> {
	// % protected region % [Add extra class fields here] off begin
	// % protected region % [Add extra class fields here] end

	public componentDidMount(): void {
		this.initConditions();
		this.initValidators();
		// % protected region % [Add additional initializers here] off begin
		// % protected region % [Add additional initializers here] end
	}

	private getQuestionType(): string {
		// % protected region % [Override getQuestionType here] off begin
		const { question, schema } = this.props;
		let questionType: string = '';

		if (question.showConditions) {
			question.showConditions.forEach(c => {
				const questionTypes = _.flatMap(schema.slides, x => getNestedQuestions(x.contents))
					.find(q => q.id === c.path);
				if (questionTypes) {
					questionType = questionTypes.questionType;
				}
			});
		}

		return questionType;
		// % protected region % [Override getQuestionType here] end
	}

	private getConditionOptions(): Array<ComboBoxOptions> {
		// % protected region % [Override getConditionOptions here] on begin
		const questionObject = customQuestions.find(q => q.questionType === this.getQuestionType());

		if (questionObject && questionObject.conditionOptions) {
			return questionObject.conditionOptions;
		}

		return [{ display: '', value: '' }];
		// % protected region % [Override getConditionOptions here] end
	}

	private static getConditionPathOptions(contents: Contents): Array<ComboBoxOptions> {
		// % protected region % [Override getNestedQuestions here] off begin
		return getNestedQuestions(contents).map(q => ({ display: q.title, value: q.id }));
		// % protected region % [Override getNestedQuestions here] end
	}

	private static getValidatorOptions(question: Question, selectedValidator?: Validator): Array<ComboBoxOptions> {
		// % protected region % [Override getValidatorOptions here] on begin
		const questionObject = customQuestions.find(q => { return q.questionType === question.questionType; });
		if (questionObject && questionObject.validatorOptions && question.validators) {
			// Get array of already present validators excluding the current selected validator
			const validators = question.validators
				.filter(validator => validator !== selectedValidator)
				.map(validator => validator.validator);
			// Filter out any validators that are already present
			const validatorOptions = questionObject.validatorOptions
				.filter(validator => !validators?.includes(validator.value));
			if (validatorOptions.length !== 0) {
				return validatorOptions;
			}
		}

		return [{ display: '', value: '' }];
		// % protected region % [Override getValidatorOptions here] end
	}

	public static getStylingOptions(question: Question): Array<ComboBoxOptions> {
		// % protected region % [Override getStylingOptions here] on begin
		const questionObject = customQuestions.find(q => { return q.questionType === question.questionType; });

		if (questionObject && questionObject.stylingOptions) {
			return questionObject.stylingOptions;
		}

		return [{ display: '', value: '' }];
		// % protected region % [Override getStylingOptions here] end
	}

	private static getQuestionTypeClean(questionType: string): string {
		// % protected region % [Override getQuestionTypeClean here] on begin
		const questionName = customQuestions.find(q => { return q.questionType === questionType; });

		return questionName ? questionName.displayName : 'Question';
		// % protected region % [Override getQuestionTypeClean here] end
	}

	@action
	public initConditions = (): void => {
		// % protected region % [Override initConditions here] off begin
		const { question } = this.props;

		if (question.options && !question.options.values) {
			question.options.values = [];
		}

		if (!question.showConditions) {
			question.showConditions = [];
		}
		// % protected region % [Override initConditions here] end
	};

	@action
	public initValidators = (): void => {
		// % protected region % [Override initValidators here] off begin
		const { question } = this.props;

		if (!question.validators) {
			question.validators = [];
		}
		// % protected region % [Override initValidators here] end
	};

	@action
	public addCondition = (): void => {
		// % protected region % [Override addCondition here] off begin
		const { question } = this.props;

		if (question.showConditions) {
			question.showConditions.push({
				path: '',
				condition: 'equal',
				value: '',
			});
		}
		// % protected region % [Override addCondition here] end
	};

	@action
	public addValidator = (): void => {
		// % protected region % [Override addValidator here] off begin
		const { question } = this.props;

		if (!question.validators) {
			question.validators = [];
		}

		question.validators.push({
			additionalData: '',
			path: question.id,
			validator: 'placeholder',
		});
		// % protected region % [Override addValidator here] end
	};

	public removeCondition = (index: number): () => void => {
		// % protected region % [Override removeCondition here] off begin
		const { question } = this.props;

		return (): void => {
			runInAction(() => {
				if (question.showConditions) {
					question.showConditions.splice(index, 1);
				}
			});
		};
		// % protected region % [Override removeCondition here] end
	};

	public removeValidator = (index: number): () => void => {
		// % protected region % [Override removeValidator here] off begin
		const { question } = this.props;

		return (): void => {
			runInAction(() => {
				if (question.validators) {
					question.validators.splice(index, 1);
				}
			});
		};
		// % protected region % [Override removeValidator here] end
	};

	// % protected region % [customize the TileOptions renderConditions function] off begin
	private renderConditions = (): ReactNode => {
		const { question, schema } = this.props;

		if (question.showConditions) {
			return (
				<>
					<h4>Conditions</h4>
					{question.showConditions.map((c, i) => {
						return (
							<React.Fragment key={c.condition + c.value}>
								<Combobox
									model={c}
									modelProperty="path"
									label="Field"
									options={
										_.flatMap(schema.slides, x => TileOptions.getConditionPathOptions(x.contents))
									}
									sideButton={(
										<Button
											colors={Colors.Primary}
											display={Display.Solid}
											onClick={this.removeCondition(i)}
										>
											Remove
										</Button>
									)}
								/>

								<Combobox
									// Have to change key when options change as a change of options won't force a re-render
									key={JSON.stringify(this.getConditionOptions())}
									model={c}
									modelProperty="condition"
									label="Is"
									options={this.getConditionOptions()}
								/>

								{this.getQuestionType() === 'datetime' ? (
									<DateTimePicker
										model={c}
										modelProperty="value"
										labelVisible={false}
									/>
								)
									: <TextField model={c} modelProperty="value" />}
							</React.Fragment>
						);
					})}
				</>
			);
		}

		return null;
	};
	// % protected region % [customize the TileOptions renderConditions function] end

	// % protected region % [customize the TileOptions renderValidators function] off begin
	private renderValidators = (): ReactNode => {
		const { question } = this.props;

		if (question.validators) {
			return (
				<>
					<h4>Validator</h4>
					{question.validators.map((v, i) => {
						return (
							<React.Fragment key={v.validator + v.additionalData}>
								<Combobox
									key={JSON.stringify(TileOptions.getValidatorOptions(question))}
									model={v}
									modelProperty="validator"
									label="Validation Type"
									options={TileOptions.getValidatorOptions(question, v)}
									sideButton={(
										<Button
											colors={Colors.Primary}
											display={Display.Solid}
											onClick={this.removeValidator(i)}
										>
											Remove
										</Button>
									)}
								/>

								{getAdditionalAttributeElement(v)}

							</React.Fragment>
						);
					})}
				</>
			);
		}

		return null;
	};
	// % protected region % [customize the TileOptions renderValidators function] end

	// % protected region % [customize the TileOptions render function] on begin
	public render(): ReactNode {
		const {
			question,
			hasShowConditions,
			hasValidators,
			children,
			hasTooltip,
			tooltipInputLabel,
			optionsChildren,
		} = this.props;

		const questionTypeTitle = `${TileOptions.getQuestionTypeClean(question.questionType)} title`;

		return (
			<>
				<h4>Options</h4>

				<TextField model={question} modelProperty="title" label={questionTypeTitle} />

				{hasTooltip
					&& (
						<TextField
							model={question}
							modelProperty="toolTip"
							label={tooltipInputLabel ?? 'Tool Tip'}
						/>
					)}

				{optionsChildren ?? optionsChildren}

				<hr className="sidebar-separator" />

				{hasShowConditions && this.renderConditions()}

				{hasShowConditions && (
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						onClick={this.addCondition}
					>
						Add new condition
					</Button>
				)}

				{hasShowConditions && <hr className="sidebar-separator" />}

				{hasValidators && this.renderValidators()}

				{hasValidators && TileOptions.getValidatorOptions(question).length && (
					<Button
						colors={Colors.Primary}
						display={Display.Outline}
						disabled={TileOptions.getValidatorOptions(question)[0].value === ''}
						onClick={this.addValidator}
					>
						Add new validator
					</Button>
				)}

				{hasValidators && <hr className="sidebar-separator" />}

				{children && children}
			</>
		);
	}
	// % protected region % [customize the TileOptions render function] end
}
