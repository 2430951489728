import React, {
	useCallback, useContext, useState,
} from 'react';
import If from '../../Components/If/If';
import SlideHeader from './SlideHeader';
import { Button } from '../../Components/Button/Button';
import rightSideImage from '../../../Assets/Form/Images/form-landing-right.png';
import QRCode from 'qrcode.react';
import leftSideImage from '../../../Assets/Form/Images/circle-two-outlines-cropped.png';
import { Progress } from 'semantic-ui-react';
import { FormEntityTile } from '../../../Forms/FormEntityTile';
import alertToast from '../../../Util/ToastifyUtils';
import BackgroundTopImage from '../../../Assets/Form/Images/circle-green.png';
import BackgroundRightImage from '../../../Assets/Form/Images/half-circle-overlay-blue.png';
import BackgroundRightMidImage from '../../../Assets/Form/Images/filled-circle-two-outlines.png';
import BackgroundLeftMidImage from '../../../Assets/Form/Images/filled-circle-green.png';
import CFEPLogo from '../../../Assets/Form/Images/CFEP_RGB_Transparent.png';
import PublicSubmissionEndScreenFooter from './PublicSubmissionEndScreenFooter';
import { FormEntity } from '../../../Models/Entities';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { SubmitFormProgressBarContext } from '../../Components/ProgressBar/SubmitFormProgressBarContext';
import FormTimerModal from 'Forms/FormTimerModal';

interface IPublicSubmissionForm {
	data: FormEntity;
	submissionState: 'start' | 'form' | 'end',
	setSubmissionState: (state: 'start' | 'form' | 'end') => void
}

let autoBackToStartTimer: NodeJS.Timeout | undefined;

const PublicSubmissionForm = observer((props: IPublicSubmissionForm) => {
	const { data, submissionState, setSubmissionState } = props;
	const { progressState } = useContext(SubmitFormProgressBarContext);
	const [formTimerOpen, setFormTimerOpen] = useState<boolean>(false);

	const openFormTimer = () => {
		setFormTimerOpen(true);
	};

	const closeFormTimer = (backToStart: boolean = false) => {
		setFormTimerOpen(false);

		if (backToStart) {
			backToStartOnClick();
		}
	};

	const siteName = (data.site.name);

	const backToStartOnClick = useCallback(() => {
		setSubmissionState('start');
	}, [setSubmissionState]);

	return (
		<div className="body-content">
			<If condition={submissionState === 'start'}>
				<SlideHeader siteName={siteName} />
				<div className="content slides-landing-content">
					<div className="slides-landing-body">
						<div>
							<h3>Help us understand your experience at {siteName} today</h3>
							<h5>We welcome your feedback in this one to two minute survey.</h5>
							<p>
								Your responses are confidential and will
								help us to better understand what’s important to you.
								What you say matters to us.
							</p>
							<p>
								We want to give you the best experience possible.
								Your feedback will inform improvement and we may use it for research.
							</p>
							<p>
								All questions are optional. You can submit your feedback at
								any time using the submit button on the final screen.
							</p>
						</div>
						<Button
							type="submit"
							className="icon-arrow-right icon-right btn--solid"
							onClick={() => setSubmissionState('form')}
						>
							Start Survey
						</Button>
					</div>
					<img src={rightSideImage} className="slides-landing-img" alt="woman helping patient" />
					<div className="qr-code-wrapper">
						<QRCode value={window.location.href} />
						<h6>Scan to complete survey on your own device</h6>
					</div>
					<img
						src={leftSideImage}
						alt=""
						style={{
							position: 'absolute',
							width: '200px',
							left: 0,
							bottom: 0,
							opacity: '20%',
						}}
					/>
				</div>
				<div className="slides-copyright__container">
					<p className="slides-copyright">
						© Client Focused Evaluation Program (CFEP) Surveys {(new Date().getFullYear())}
					</p>
				</div>
			</If>
			<If condition={submissionState === 'form'}>
				<div className="header slide-header">
					<p style={{ margin: 0 }}>{siteName}</p>
					<Progress
						className="slider-progress-bar"
						percent={progressState.progress}
						size="small"
					/>
					<img
						src={CFEPLogo}
						height={55}
						width="auto"
						alt="CFEP Logo"
					/>
				</div>
				<div className="slides-content__container">
					{
						data
							? (
								<FormEntityTile
									model={data}
									onAfterSubmit={() => {
										setSubmissionState('end');
										alertToast('Successfully submitted form', 'success');
									}}
								/>
							)
							: 'LOADING'
					}
				</div>
				<div className="slides-copyright__container">
					<p className="slides-copyright">
						© Client Focused Evaluation Program (CFEP) Surveys {(new Date().getFullYear())}
					</p>
				</div>
				<img
					src={BackgroundTopImage}
					alt=""
					className="slider-img-positioned"
					style={{
						position: 'absolute',
						top: -420,
						right: 133,
						height: 600,
						width: 'auto',
						opacity: 0.45,
					}}
				/>
				<img
					src={BackgroundRightImage}
					alt=""
					className="slider-img-positioned"
					style={{
						position: 'absolute',
						top: -114,
						right: -100,
						height: 420,
						width: 'auto',
						opacity: 0.45,
						transform: 'rotate(129deg)',
					}}
				/>
				<img
					src={BackgroundRightMidImage}
					alt=""
					className="slider-img-positioned"
					style={{
						position: 'absolute',
						top: '33%',
						right: -210,
						height: 370,
						width: 'auto',
						opacity: 0.35,
						transform: 'rotate(333deg)',
					}}
				/>
				<img
					src={BackgroundLeftMidImage}
					className="slider-img-positioned"
					alt=""
					style={{
						position: 'absolute',
						bottom: '13%',
						left: -110,
						height: 270,
						width: 'auto',
						opacity: 0.25,
						transform: 'rotate(53deg)',
					}}
				/>
			</If>
			<If condition={submissionState === 'end'}>
				<SlideHeader siteName={siteName} />
				<div className="content form__endscreen-container">
					<div className="form__endscreen-content">
						<img src={CFEPLogo} alt="CFEP Logo" />
						<h2>Thank you for your feedback</h2>
						<h5>We want to give you the best experience of care possible</h5>
						<p>
							If you have questions about this survey, please contact Client Focused Evaluation Program
							(CFEP) Surveys <a href="www.cfepsurveys.com.au">www.cfepsurveys.com.au</a>
						</p>
					</div>
					<img
						src={BackgroundTopImage}
						alt=""
						className="slider-img-positioned"
						style={{
							position: 'absolute',
							top: -420,
							right: 133,
							height: 600,
							width: 'auto',
							opacity: 0.45,
						}}
					/>
					<img
						src={BackgroundRightImage}
						alt=""
						className="slider-img-positioned"
						style={{
							position: 'absolute',
							top: -114,
							right: -100,
							height: 420,
							width: 'auto',
							opacity: 0.45,
							transform: 'rotate(129deg)',
						}}
					/>
					<img
						src={BackgroundRightMidImage}
						alt=""
						className="slider-img-positioned"
						style={{
							position: 'absolute',
							top: '33%',
							right: -210,
							height: 370,
							width: 'auto',
							opacity: 0.35,
							transform: 'rotate(333deg)',
						}}
					/>
					<img
						src={BackgroundLeftMidImage}
						className="slider-img-positioned"
						alt=""
						style={{
							position: 'absolute',
							bottom: '13%',
							left: -110,
							height: 270,
							width: 'auto',
							opacity: 0.25,
							transform: 'rotate(53deg)',
						}}
					/>
				</div>
				<div className="slides-copyright-endscreen__container hide-small">
					<PublicSubmissionEndScreenFooter backToStartOnClick={() => setSubmissionState('start')} />
				</div>
				<div className="slides-copyright-endscreen__container hide-large">
					<PublicSubmissionEndScreenFooter backToStartOnClick={() => setSubmissionState('start')} />
				</div>
			</If>
			<FormTimerModal
				open={formTimerOpen}
				openModal={openFormTimer}
				closeModal={closeFormTimer}
				backToStart={backToStartOnClick}
				currentStep={submissionState}
			/>
		</div>
	);
});

export default PublicSubmissionForm;
