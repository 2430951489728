import React, { Component, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Form, Question } from '../../Schema/Question';
import { TextArea } from 'Views/Components/TextArea/TextArea';

export interface CustomFormTileOptionsProps {
	schema: Form;
	question: Question;
}

@observer
export default class CustomFormStatementOptions extends Component<CustomFormTileOptionsProps> {
	public render(): ReactNode {
		const { question } = this.props;

		return (
			<>
				<h4>Statement Text</h4>
				<TextArea
					model={question}
					modelProperty="content"
				/>
				<hr className="sidebar-separator" />
			</>
		);
	}
}
