import * as React from 'react';
import moment from 'moment';

import { Button, Display, Colors } from 'Views/Components/Button/Button';
import { DisplayType } from 'Views/Components/Models/Enums';
import { DateRangePicker } from 'Views/Components/DateRangePicker/DateRangePicker';

export interface IDatePickerMenuProps {
	onUpdate: (range: Date[]) => any,
	range: Date[],
}

const DatePickerMenu: React.FC<IDatePickerMenuProps> = ({ onUpdate, range }) => {
	// range reflects the current range of the page
	// local range reflect the range in the datepicker menu
	// (range is what is currently applied to the dashboard, local range is applied when 'update' is pressed)
	const [localRange, setLocalRange] = React.useState<Date[]>(range);

	// create a small mock model sop we can use the daterangepicker
	const model = React.useMemo(
		() => ({
			range: [moment(range[0]).toDate(), moment(range[1]).endOf('day').toDate()],
		}),
		// we disable updating the range here because we only want to do this on first call so the flow of information
		// is unidirectional from the daterangepicker model to the range in parent component
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const label = React.useMemo(() => {
		return `${moment(range[0]).format('MMM D YYYY')} - ${moment(range[1]).format('MMM D YYYY')}`;
	}, [range]);

	return (
		<div>
			<DateRangePicker
				model={model}
				modelProperty="range"
				name="range"
				placeholder="Select Period..."
				className="general-date-range-picker"
				displayType={DisplayType.INLINE}
				// eslint-disable-next-line max-len
				label={label}
				labelVisible
				onAfterChange={newRange => setLocalRange(newRange)}
			/>
			<Button
				display={Display.Solid}
				colors={Colors.Primary}
				onClick={() => onUpdate(localRange)}
			>
				Update
			</Button>
		</div>
	);
};

export default DatePickerMenu;
