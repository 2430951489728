import React from 'react';
import { FormEntity } from '../../Models/Entities';
import Spinner from '../Components/Spinner/Spinner';
import useAsync from '../../Hooks/useAsync';
import { TextField } from '../Components/TextBox/TextBox';
import { Form } from '../Components/Form/Form';
import QRCode from 'qrcode.react';
import { FormTileCustom } from '../../Forms/FormTileCustom';

interface IFormViewProps {
	id: string
}

const EditFormView = (props: IFormViewProps) => {
	const { id } = props;

	const { type, error, data } = useAsync(() => FormEntity.fetchAndCount<FormEntity>(
		{
			id: id,
		},
		`
			publishedVersion {
				formData
			}
		`,
	), [id]);

	if (type === 'loading') {
		return <Spinner />;
	}

	if (type === 'error') {
		return <div>{error.response.data}</div>;
	}

	if (data.data.length === 0) {
		return <div>Nothing!</div>;
	}

	const form = data.data[0];
	const formUrl = `${window.location.origin}/submitform/${form.slug}`;

	return (
		<div className="crud--FormEntity">
			<div className="crud-component">
				<section className="crud__view">
					<div className="crud__header">
						<h2>Form Details</h2>
					</div>
					<div>
						<QRCode value={formUrl} />
						<p>{formUrl}</p>
					</div>
					<Form>
						<TextField model={form} modelProperty="id" isDisabled label="Form Id" />
						<TextField model={form} modelProperty="slug" isDisabled label="Slug" />
						<TextField
							label="Created"
							model={form}
							modelProperty="created"
							isDisabled
						/>
						<TextField
							label="Modified"
							model={form}
							modelProperty="modified"
							isDisabled
						/>
					</Form>
					<div className="crud__header">
						<h2>Form Preview</h2>
					</div>
					{form.publishedVersion && form.publishedVersion.formData
						? (
							<FormTileCustom
								model={form}
								schema={form.publishedVersion.formData}
								className="forms-preview"
							/>
						)
						: (
							<div>No published version found!</div>
						)}
				</section>
			</div>
		</div>
	);
};

export default EditFormView;
